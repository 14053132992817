import { useQuery } from '@tanstack/react-query';
import {
  getExercise,
  getExerciseClassrooms,
  getExercisesByType,
  getMultipleChoiceExerciseClassrooms,
  getReadingExerciseClassrooms,
  getUncompletedExercises,
} from 'api/exercises';
import { ExerciseType, QueryKeyByType } from 'constants/exercises';
import { usePaginate } from 'hooks/usePaginate';

export const useGetAllReadingExercises = ({ title = '', ...props } = {}) => {
  const baseQueryKey = QueryKeyByType[ExerciseType.Reading];

  return usePaginate({
    queryKey: title ? [(baseQueryKey, title)] : [baseQueryKey],
    queryFn: ({ page }) =>
      getExercisesByType(ExerciseType.Reading, { page, title }),
    ...props,
  });
};

export const useGetAllMultipleChoiceExercises = ({
  title = '',
  ...props
} = {}) => {
  const baseQueryKey = QueryKeyByType[ExerciseType.MultipleChoiceCloze];

  return usePaginate({
    queryKey: title ? [(baseQueryKey, title)] : [baseQueryKey],
    queryFn: ({ page }) =>
      getExercisesByType(ExerciseType.MultipleChoiceCloze, { page, title }),
    ...props,
  });
};

export const useGetAllWritingExercises = ({ title = '', ...props } = {}) => {
  const baseQueryKey = QueryKeyByType[ExerciseType.Writing];

  return usePaginate({
    queryKey: title ? [(baseQueryKey, title)] : [baseQueryKey],
    queryFn: ({ page }) =>
      getExercisesByType(ExerciseType.Writing, { page, title }),
    ...props,
  });
};

export const useExerciseClassrooms = ({ exerciseId, type, ...options }) => {
  const queryKey = [`${type}-exercise-classrooms`, exerciseId];

  return useQuery({
    queryKey,
    queryFn: () => getExerciseClassrooms(exerciseId, type),
    ...options,
  });
};

export const useGetExercise = ({ exerciseId, type, ...options }) => {
  const queryKey = [`${type}-exercise`, exerciseId];

  return useQuery({
    queryKey,
    queryFn: () => getExercise(exerciseId, type),
    ...options,
  });
};

export const useGetUncompletedExercises = ({ userId, ...options }) => {
  const queryKey = [`uncompleted-exercises-${userId}`];

  return useQuery({
    queryKey,
    queryFn: () => getUncompletedExercises(userId),
    ...options,
  });
};
