import { Paths } from 'constants/paths';

export const ExerciseType = {
  MultipleChoiceCloze: 'multiple_choice_fce',
  Reading: 'reading_camla',
  Custom: 'custom',
  Writing: 'writing',
};

export const EXERCISES = [
  {
    title: 'Multiple Choice Cloze',
    description: 'Choose the correct word to complete the sentence',
    path: Paths.MultipleChoiceCloze,
    type: ExerciseType.MultipleChoiceCloze,
  },
  {
    title: 'Reading',
    description: 'Read the passage and answer the questions',
    path: Paths.Reading,
    type: ExerciseType.Reading,
  },
  {
    title: 'Writing',
    description: 'Write an essay given a prompt',
    path: Paths.Writing,
    type: ExerciseType.Writing,
  },
];

export const ExerciseTypeMap = {
  [ExerciseType.MultipleChoiceCloze]: 'Multiple Choice Cloze',
  [ExerciseType.Reading]: 'Reading',
  [ExerciseType.Custom]: 'Custom',
  [ExerciseType.Writing]: 'Writing',
};

export const QueryKeyByType = {
  [ExerciseType.MultipleChoiceCloze]: 'multiple-choice-cloze-exercises',
  [ExerciseType.Reading]: 'reading-exercises',
  [ExerciseType.Custom]: 'custom-exercises',
  [ExerciseType.Writing]: 'writing-exercises',
};
