import classNames from 'classnames';
import { Card, CardContent, CardHeader } from 'components/ui/card';
import { Separator } from 'components/ui/separator';
import { TypographyH3 } from 'components/ui/typography/typographyH3';

const ReadOnlyReading = ({ exercise }) => {
  return (
    <div className='grid gap-5 md:grid-cols-2'>
      <Card>
        <CardHeader>
          <TypographyH3 className='mb-0'>Answers and Questions</TypographyH3>
        </CardHeader>
        <CardContent>
          <div className='flex flex-col gap-4'>
            {exercise.questions.map((question, index) => {
              if (index === 0) return null;

              return (
                <div key={index}>
                  <Separator />
                  <div className='mt-4'>
                    <p className='mb-3 font-bold'>{question}</p>
                    <ul>
                      {exercise.options[index].map((option, i) => {
                        const isCorrect = option === exercise.answers[index];

                        return (
                          <li
                            key={i}
                            className={classNames({
                              'text-green-500': isCorrect,
                            })}
                          >
                            {i + 1} - {option}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              );
            })}
          </div>
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <TypographyH3>{exercise.title}</TypographyH3>
        </CardHeader>
        <CardContent className='text-justify leading-7'>
          {exercise.passage}
        </CardContent>
      </Card>
    </div>
  );
};

export default ReadOnlyReading;
