import ChatHistory from 'components/ChatHistory';
import BackSection from 'components/common/BackSection';
import Loader from 'components/common/Loader';
import CustomAttempt from 'components/exercises/attempts/CustomAttempt';
import MultipleChoiceAttempt from 'components/exercises/attempts/MultipleChoiceAttempt';
import ReadingAttempt from 'components/exercises/attempts/ReadingAttempt';
import WritingAttempt from 'components/exercises/attempts/WritingAttempt';
import { Badge } from 'components/ui/badge';
import { Button } from 'components/ui/button';
import { TypographyH2 } from 'components/ui/typography/typographyH2';
import { ExerciseType, ExerciseTypeMap } from 'constants/exercises';
import { useGetAttempt } from 'hooks/useAttempts';
import { useGetChatHistory } from 'hooks/useChats';
import { useGetStudent } from 'hooks/useUsers';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

const getAttemptByExerciseType = (exerciseType, attempt) => {
  const attemptByExerciseType = {
    [ExerciseType.Reading]: <ReadingAttempt attempt={attempt} />,
    [ExerciseType.MultipleChoiceCloze]: (
      <MultipleChoiceAttempt attempt={attempt} />
    ),
    [ExerciseType.Custom]: <CustomAttempt attempt={attempt} />,
    [ExerciseType.Writing]: <WritingAttempt attempt={attempt} />,
  };

  return attemptByExerciseType[exerciseType];
};

const Attempt = () => {
  const [open, setOpen] = useState(false);
  const { studentId, attemptId } = useParams();
  const { data: attempt, isLoading: isLoadingAttempt } =
    useGetAttempt(attemptId);
  const { data: student, isLoading: isLoadingUser } = useGetStudent(studentId);
  const {
    data: messages,
    isSuccess: isSuccessChat,
    isLoading: isLoadingChat,
  } = useGetChatHistory(attempt?.exercise_id, studentId);

  if (isLoadingAttempt || isLoadingUser) {
    return <Loader />;
  }

  if (!attempt && !isLoadingAttempt && !isLoadingUser) {
    return (
      <div className='flex flex-col gap-3 px-4 py-6 sm:container'>
        <BackSection />
        <section className='mb-5'>
          <p>No attempt</p>
        </section>
      </div>
    );
  }

  return (
    <div className='flex flex-col gap-3 px-4 py-6 sm:container'>
      <ChatHistory
        messages={messages}
        isSuccess={isSuccessChat}
        isLoading={isLoadingChat}
        open={open}
        setOpen={setOpen}
      />
      <BackSection />
      <section className='flex flex-col justify-between gap-4 sm:flex-row sm:items-start'>
        <div className='flex items-end gap-2'>
          <div className='flex flex-col items-start gap-2'>
            <TypographyH2 className='pb-0'>
              {/* TODO: Change this for real name */}
              {student.name}&apos;s attempt of &ldquo;{attempt.exercise_title}
              &ldquo;
            </TypographyH2>
            <Badge className='h-fit bg-sky-500'>
              {ExerciseTypeMap[attempt.exercise_type]}
            </Badge>
          </div>
        </div>
        {attempt.exercise_type != ExerciseType.Custom && (
          <Button
            className='!bg-slate-100 text-black hover:bg-slate-200'
            onClick={() => setOpen(true)}
          >
            View chat history
          </Button>
        )}
      </section>
      <section className='mt-2'>
        {getAttemptByExerciseType(attempt.exercise_type, attempt)}
      </section>
    </div>
  );
};

export default Attempt;
