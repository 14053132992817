import BackSection from 'components/common/BackSection';
import SignUpForm from 'components/forms/SignUpForm';
import { Button } from 'components/ui/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';
import { Paths } from 'constants/paths';
import { Roles } from 'constants/roles';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const RoleForm = ({ setRole }) => {
  return (
    <Select onValueChange={value => setRole(value)}>
      <SelectTrigger>
        <SelectValue placeholder='Please select your role' />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value={Roles.Student}>Student</SelectItem>
        <SelectItem value={Roles.Teacher}>Teacher</SelectItem>
      </SelectContent>
    </Select>
  );
};

const SignUpPage = () => {
  const navigate = useNavigate();
  const [role, setRole] = useState(null);

  const navigateToSignIn = () => {
    navigate(Paths.SignIn);
  };

  return (
    <div className='min-h-full'>
      {role && (
        <div className='p-2'>
          <BackSection onClick={() => setRole(null)} />
        </div>
      )}
      <div
        className={`flex flex-col justify-center px-6 py-12 lg:px-8 ${!role && 'h-screen gap-5'}`}
      >
        <div className='flex flex-col items-center sm:mx-auto sm:w-full sm:max-w-sm'>
          <h2 className='text-md -mb-2 text-center leading-9 tracking-tight text-gray-500'>
            Welcome to
          </h2>
          <img src='/studia-logo.png' alt='Studia Logo' className='w-60' />
        </div>
        <div className='sm:mx-auto sm:w-full sm:max-w-sm'>
          {role ? <SignUpForm role={role} /> : <RoleForm setRole={setRole} />}
        </div>
        <p className='mt-5 text-center text-sm text-gray-500'>
          Already a member?
          <Button variant='link' onClick={navigateToSignIn}>
            Sign in here
          </Button>
        </p>
      </div>
    </div>
  );
};

export default SignUpPage;
