import { useEffect, useState } from 'react';
import { TypographyH2 } from 'components/ui/typography/typographyH2';
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs';
import { ExerciseType, ExerciseTypeMap } from 'constants/exercises';
import { useGetClassroomsByTeacher } from 'hooks/useClassrooms';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'hooks/useDebounce';
import { Input } from 'components/ui/input';
import ExercisesTable from 'components/tables/ExercisesTable';
import {
  useGetAllMultipleChoiceExercises,
  useGetAllReadingExercises,
  useGetAllWritingExercises,
} from 'hooks/useExercises';

const Exercises = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [title, setTitle] = useState('');
  const debounceTitle = useDebounce(title, 500);

  const [exercisesType, setExercisesType] = useState(
    ExerciseType.MultipleChoiceCloze,
  );

  const { data: classrooms, isSuccess } = useGetClassroomsByTeacher();

  const onTabChange = tab => {
    const currentParams = Object.fromEntries([...searchParams]);

    setExercisesType(tab);
    setSearchParams({ type: tab, ...currentParams });
    setTitle('');
  };

  useEffect(() => {
    if (searchParams.get('type')) {
      setExercisesType(searchParams.get('type'));
    }
  }, [classrooms]);

  return (
    <div className='px-4 py-6 sm:container'>
      <TypographyH2 className='mb-3'>Exercises</TypographyH2>
      {isSuccess && (
        <Tabs
          defaultValue={
            searchParams.get('type') || ExerciseType.MultipleChoiceCloze
          }
          className='w-full'
          value={exercisesType}
          onValueChange={onTabChange}
        >
          <TabsList className='w-full'>
            <TabsTrigger
              value={ExerciseType.MultipleChoiceCloze}
              className='w-full'
            >
              {ExerciseTypeMap[ExerciseType.MultipleChoiceCloze]}
            </TabsTrigger>
            <TabsTrigger value={ExerciseType.Reading} className='w-full'>
              {ExerciseTypeMap[ExerciseType.Reading]}
            </TabsTrigger>
            <TabsTrigger value={ExerciseType.Writing} className='w-full'>
              {ExerciseTypeMap[ExerciseType.Writing]}
            </TabsTrigger>
          </TabsList>
          <TabsContent
            value={ExerciseType.MultipleChoiceCloze}
            className='space-y-2'
          >
            <Input
              placeholder='Search by title'
              onChange={event => setTitle(event.target.value)}
            />
            <ExercisesTable
              currentClassrooms={classrooms}
              debounceTitle={debounceTitle}
              useGetAllExercises={useGetAllMultipleChoiceExercises}
            />
          </TabsContent>
          <TabsContent value={ExerciseType.Reading} className='space-y-2'>
            <Input
              placeholder='Search by title'
              onChange={event => setTitle(event.target.value)}
            />
            <ExercisesTable
              currentClassrooms={classrooms}
              debounceTitle={debounceTitle}
              useGetAllExercises={useGetAllReadingExercises}
            />
          </TabsContent>
          <TabsContent value={ExerciseType.Writing} className='space-y-2'>
            <Input
              placeholder='Search by title'
              onChange={event => setTitle(event.target.value)}
            />
            <ExercisesTable
              currentClassrooms={classrooms}
              debounceTitle={debounceTitle}
              useGetAllExercises={useGetAllWritingExercises}
            />
          </TabsContent>
        </Tabs>
      )}
    </div>
  );
};

export default Exercises;
