import { signIn, signUp } from 'api/auth';
import { useContext, createContext, useState } from 'react';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('user')) || null,
  );

  const signInAction = async ({ email, password }) => {
    const {
      token,
      email: userEmail,
      id,
      name,
      role,
    } = await signIn({ email, password });

    localStorage.setItem(
      'user',
      JSON.stringify({ email: userEmail, id, name, role }),
    );
    setUser({ email: userEmail, id, name, role });

    localStorage.setItem('token', token);
    setToken(token);
  };

  const signUpAction = async ({ email, password, name, role, code }) => {
    const {
      token,
      email: userEmail,
      id,
      name: userName,
      role: userRole,
    } = await signUp({ email, password, name, role, code });

    localStorage.setItem(
      'user',
      JSON.stringify({ email: userEmail, id, name: userName, role }),
    );
    setUser({ email: userEmail, id, name: userName, role: userRole });

    localStorage.setItem('token', token);
    setToken(token);
  };

  const logOut = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setToken('');
    setUser(null);
  };

  return (
    <AuthContext.Provider
      value={{ signInAction, logOut, signUpAction, token, user }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
