import { Card, CardContent, CardHeader } from 'components/ui/card';
import { TypographyH3 } from 'components/ui/typography/typographyH3';

const ReadOnlyWriting = ({ exercise }) => {
  return (
    <div className='grid gap-5 md:grid-cols-2'>
      <Card>
        <CardHeader>
          <TypographyH3 className='mb-0'>Tips</TypographyH3>
        </CardHeader>
        <CardContent>
          <div className='leading-8 h-full space-y-4 md:pt-6'>
            <ol className='list-disc ml-4'>
              {exercise.tips.map((hint, index) => (
                <li key={index}>{hint}</li>
              ))}
            </ol>
          </div>
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <TypographyH3>{exercise.title}</TypographyH3>
        </CardHeader>
        <CardContent className='text-justify leading-7'>
          {exercise.text.map((text, index) => (
            <p key={index}>{text}</p>
          ))}
        </CardContent>
      </Card>
    </div>
  );
};

export default ReadOnlyWriting;
