import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from 'components/ui/dialog';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from 'components/ui/form';
import { useForm } from 'react-hook-form';
import validator from 'lib/validator';
import { useEffect, useMemo } from 'react';
import { Switch } from 'components/ui/switch';
import { useExerciseClassrooms } from 'hooks/useExercises';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ExerciseType, QueryKeyByType } from 'constants/exercises';
import { updateExerciseClassrooms } from 'api/exercises';

const ActiveExerciseDialog = ({ exerciseId, type }) => {
  const queryClient = useQueryClient();
  const { data: exercise, isSuccess } = useExerciseClassrooms({
    exerciseId,
    type,
  });

  const { mutateAsync } = useMutation({
    mutationFn: updateExerciseClassrooms,
    onSuccess: () => {
      queryClient.refetchQueries([`${type}-exercise-classrooms`, exerciseId]);

      queryClient.refetchQueries([QueryKeyByType[type]]);
    },
  });

  const initialClassrooms = useMemo(() => {
    if (!isSuccess) {
      return {};
    }

    return exercise.classrooms?.reduce((acc, classroom) => {
      acc[classroom.id] = classroom.active;
      return acc;
    }, {});
  }, [exercise, isSuccess]);

  const schema = validator
    .object(
      Object.keys(initialClassrooms).reduce((acc, c) => {
        acc[c] = validator.boolean();
        return acc;
      }, {}),
    )
    .required();

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialClassrooms,
  });

  useEffect(() => {
    form.reset(initialClassrooms);
  }, [initialClassrooms, form]);

  const onSubmit = data => {
    const activate = [];
    const deactivate = [];

    for (const [id, active] of Object.entries(data)) {
      if (active !== initialClassrooms[id]) {
        if (active) {
          activate.push(id);
        } else {
          deactivate.push(id);
        }
      }
    }

    return mutateAsync({
      exerciseId,
      exerciseType: type,
      data: { activate, deactivate },
    });
  };

  return (
    <Dialog>
      <DialogTrigger>
        <Button>Classroom exercise settings</Button>
      </DialogTrigger>
      {isSuccess && (
        <DialogContent className='flex max-h-[41rem] flex-col'>
          <DialogHeader>
            <DialogTitle>Manage classroom exercise settings</DialogTitle>
            <DialogDescription>
              Select the classrooms where you want to activate or deactivate
              this exercise.
            </DialogDescription>
          </DialogHeader>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-6'>
              <div className='max-h-[30rem] overflow-y-auto'>
                {exercise.classrooms.map(classroom => (
                  <FormField
                    key={classroom.id}
                    control={form.control}
                    name={classroom.id}
                    render={({ field }) => (
                      <FormItem className='mb-2 flex flex-row items-center justify-between rounded-lg border p-4'>
                        <div className='space-y-0.5'>
                          <FormLabel className='text-base'>
                            {classroom.name}
                          </FormLabel>
                        </div>
                        <FormControl>
                          <Switch
                            checked={field.value ?? false}
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                ))}
              </div>
              <div className='flex w-full justify-end gap-3'>
                <DialogClose asChild>
                  <Button variant='secondary'>Cancel</Button>
                </DialogClose>
                <DialogClose asChild>
                  <Button type='submit'>Save</Button>
                </DialogClose>
              </div>
            </form>
          </Form>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default ActiveExerciseDialog;
