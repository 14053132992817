import apiClient from './apiClient';

export const sendMessageToChat = async data => {
  const token = localStorage.getItem('token');

  const response = await apiClient.post('chat', data, {
    headers: {
      Authorization: token,
    },
  });

  return response.data;
};

export const getChatHistory = async (exerciseId, userId) => {
  const token = localStorage.getItem('token');

  const response = await apiClient.get(
    `chat?exercise_id=${exerciseId}&user_id=${userId}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};
