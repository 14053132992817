import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from 'components/ui/dialog';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { useForm } from 'react-hook-form';
import validator from 'lib/validator';
import { useEffect, useState } from 'react';
import { Input } from 'components/ui/input';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { enrollTeacherToClassroom } from 'api/users';
import { message } from 'antd';
import { useAuth } from 'providers/AuthProvider';

const schema = validator
  .object({
    code: validator.string().required(),
  })
  .required();

const JoinClassroomDialog = () => {
  const [open, setOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const queryClient = useQueryClient();
  const { user } = useAuth();

  const { mutateAsync, error, isError, reset } = useMutation({
    mutationFn: enrollTeacherToClassroom,
    onSuccess: () => {
      setOpen(false);

      queryClient.refetchQueries(['classrooms', user.id]);
      messageApi.success('You have successfully joined the classroom');
    },
  });

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      code: '',
    },
  });

  const onSubmit = async ({ code }) => {
    try {
      await mutateAsync(code);
    } catch (e) {
      console.log('error', e);
    }
  };

  useEffect(() => {
    form.reset();
  }, [open, form]);

  return (
    <>
      {contextHolder}
      <Dialog
        open={open}
        onOpenChange={() => {
          setOpen(prev => !prev);
          reset();
        }}
      >
        <DialogTrigger>
          <Button>Join new classroom</Button>
        </DialogTrigger>
        <DialogContent className='flex max-h-[41rem] flex-col'>
          <DialogHeader>
            <DialogTitle>Join new classroom</DialogTitle>
            <DialogDescription>
              Insert the code so you can join the classroom
            </DialogDescription>
          </DialogHeader>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-6'>
              <FormField
                control={form.control}
                name='code'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Code</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div
                className={`flex w-full ${isError ? 'justify-between' : 'justify-end'}`}
              >
                {isError && <p className='text-red-500'>{error.message}</p>}
                <Button type='submit'>Save</Button>
              </div>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default JoinClassroomDialog;
