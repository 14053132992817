import StudentStats from 'components/StudentStats';
import BackSection from 'components/common/BackSection';
import Loader from 'components/common/Loader';
import ExercisesByStudentTable from 'components/tables/ExercisesByStudentTable';
import { Badge } from 'components/ui/badge';
import EmptyState from 'components/ui/empty-state';
import { TypographyH1 } from 'components/ui/typography/typographyH1';
import { TypographyH3 } from 'components/ui/typography/typographyH3';
import { useGetAllUserAttempts } from 'hooks/useAttempts';
import { useGetClassroom } from 'hooks/useClassrooms';
import { useGetStudent } from 'hooks/useUsers';
import { useAuth } from 'providers/AuthProvider';

const AttemptsPage = () => {
  const { user } = useAuth();
  const {
    data: attempts,
    isLoading: isLoadingAttempts,
    isSuccess: isAttemptSuccess,
  } = useGetAllUserAttempts(user.id);

  const {
    data: student,
    isLoading: isLoadingUser,
    isSuccess: isStudentSuccess,
  } = useGetStudent(user.id);

  if (isLoadingAttempts || isLoadingUser) return <Loader />;

  if (!isAttemptSuccess || !isStudentSuccess) {
    return (
      <div className='flex flex-col gap-3 px-4 pt-6 sm:container'>
        <BackSection />
        <EmptyState message='Student not found' />
      </div>
    );
  }

  if (!attempts.length) {
    return (
      <div className='flex flex-col gap-3 px-4 pt-6 sm:container'>
        <BackSection />
        <EmptyState message={`${student.name} has no attempts hey`} />
      </div>
    );
  }

  return (
    <div className='flex flex-col gap-3 px-4 py-6 sm:container space-y-2'>
      <BackSection />
      <TypographyH1 className='p-0 m-0'>{student.name}'s attempts</TypographyH1>
      <section>
        <ExercisesByStudentTable attempts={attempts} />
      </section>
    </div>
  );
};

export default AttemptsPage;
