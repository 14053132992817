import { Paths } from 'constants/paths';
import { Roles } from 'constants/roles';
import Home from 'pages/Home';
import MultipleChoiceClozeExercisePage from 'pages/student/exercises/MultipleChoiceClozeExercisePage';
import ReadingExercisePage from 'pages/student/exercises/ReadingExercisePage';
import SignInPage from 'pages/auth/SignIn';
import SignUpPage from 'pages/auth/SignUp';
import { useAuth } from 'providers/AuthProvider';
import { Navigate } from 'react-router-dom';
import Student from 'pages/teacher/Student';
import Attempt from 'pages/teacher/Attempt';
import Layout from 'components/common/Layout';
import Classroom from 'pages/teacher/Classroom';
import NewClassroom from 'pages/admin/classrooms/NewClassroom';
import Exercises from 'pages/teacher/Exercises';
import Exercise from 'pages/teacher/Exercise';
import { ExerciseType } from 'constants/exercises';
import CustomExercise from 'pages/teacher/CustomExercise';
import WritingExercisePage from 'pages/student/exercises/WritingExercisePage';
import AttemptsPage from 'pages/student/AttemptsPage';

const PrivateRoute = ({ children, allowedRoles }) => {
  const { token, user } = useAuth();

  if (!token) return <Navigate to={Paths.SignIn} />;

  const isAllowed = allowedRoles.includes(user?.role);

  if (!isAllowed) return <Navigate to={Paths.Home} />;

  return <Layout>{children}</Layout>;
};

const PublicRoute = ({ children }) => {
  const { token } = useAuth();

  if (token) return <Navigate to={Paths.Home} />;

  return <Layout>{children}</Layout>;
};

const PUBLIC_ROUTES = [
  {
    key: Paths.SignUp,
    path: Paths.SignUp,
    element: <SignUpPage />,
  },
  {
    key: Paths.SignIn,
    path: Paths.SignIn,
    element: <SignInPage />,
  },
];

const PRIVATE_ROUTES = [
  {
    key: Paths.Home,
    path: Paths.Home,
    element: <Home />,
    allowedRoles: [Roles.Admin, Roles.Teacher, Roles.Student],
  },
  {
    key: Paths.MultipleChoiceCloze,
    path: Paths.MultipleChoiceCloze,
    element: <MultipleChoiceClozeExercisePage />,
    allowedRoles: [Roles.Student],
  },
  {
    key: Paths.Reading,
    path: Paths.Reading,
    element: <ReadingExercisePage />,
    allowedRoles: [Roles.Student],
  },
  {
    key: Paths.Classroom,
    path: Paths.Classroom,
    element: <Classroom />,
    allowedRoles: [Roles.Teacher, Roles.Admin],
  },
  {
    key: Paths.Student,
    path: Paths.Student,
    element: <Student />,
    allowedRoles: [Roles.Teacher, Roles.Admin],
  },
  {
    key: Paths.Attempt,
    path: Paths.Attempt,
    element: <Attempt />,
    allowedRoles: [Roles.Teacher, Roles.Admin, Roles.Student],
  },
  {
    key: Paths.NewClassroom,
    path: Paths.NewClassroom,
    element: <NewClassroom />,
    allowedRoles: [Roles.Admin],
  },
  {
    key: Paths.Exercises,
    path: Paths.Exercises,
    element: <Exercises />,
    allowedRoles: [Roles.Teacher, Roles.Admin],
  },
  {
    key: Paths.MultipleChoiceClozeExercise,
    path: Paths.MultipleChoiceClozeExercise,
    element: <Exercise type={ExerciseType.MultipleChoiceCloze} />,
    allowedRoles: [Roles.Admin, Roles.Teacher],
  },
  {
    key: Paths.ReadingExercise,
    path: Paths.ReadingExercise,
    element: <Exercise type={ExerciseType.Reading} />,
    allowedRoles: [Roles.Admin, Roles.Teacher],
  },
    {
    key: Paths.WritingExercise,
    path: Paths.WritingExercise,
    element: <Exercise type={ExerciseType.Writing} />,
    allowedRoles: [Roles.Admin, Roles.Teacher],
  },
  {
    key: Paths.ClassroomCustomExercises,
    path: Paths.ClassroomCustomExercises,
    element: <CustomExercise />,
    allowedRoles: [Roles.Admin, Roles.Teacher],
  },
  {
    key: Paths.Writing,
    path: Paths.Writing,
    element: <WritingExercisePage />,
    allowedRoles: [Roles.Student],
  },
  {
    key: Paths.Attempts,
    path: Paths.Attempts,
    element: <AttemptsPage />,
    allowedRoles: [Roles.Student],
  },
];

export const ROUTES = [
  ...PUBLIC_ROUTES.map(({ element, ...route }) => ({
    ...route,
    element: <PublicRoute>{element}</PublicRoute>,
  })),
  ...PRIVATE_ROUTES.map(({ element, allowedRoles, ...route }) => ({
    ...route,
    element: <PrivateRoute allowedRoles={allowedRoles}>{element}</PrivateRoute>,
  })),
  {
    key: 'not-found',
    path: '*',
    element: (
      <Layout>
        <div>Not Found</div>
      </Layout>
    ),
    allowedRoles: [Roles.Admin, Roles.Teacher, Roles.Student],
  },
];
