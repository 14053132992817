import apiClient from 'api/apiClient';

export const getStudent = async studentId => {
  const token = localStorage.getItem('token');

  const response = await apiClient.get(`/users/${studentId}`, {
    headers: {
      Authorization: token,
    },
  });

  return response.data;
};

export const enrollTeacherToClassroom = async classroomCode => {
  const token = localStorage.getItem('token');

  const response = await apiClient.put(
    '/users',
    {
      code: classroomCode,
    },
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

export const getStudentStats = async studentId => {
  const token = localStorage.getItem('token');

  const response = await apiClient.get(
    `/attempts/statistics?student_id=${studentId}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};
