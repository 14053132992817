import { FilterType } from 'components/ui/data-table';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { ExerciseType, ExerciseTypeMap } from 'constants/exercises';
import { DataTableWithPagination } from 'components/ui/data-table-with-pagination';
import ScoreBadge from 'components/common/ScoreBadge';

const columns = [
  {
    header: 'Title',
    accessorKey: 'exercise_title',
    id: 'exercise_title',
  },
  {
    header: 'Type',
    cell: ({ row }) => ExerciseTypeMap[row.original.exercise_type],
    id: 'type',
    accessorKey: 'exercise_type',
  },
  {
    header: 'Score',
    cell: ({ row }) => `${Math.round(row.original.score * 100)}%`,
  },
  {
    header: 'Date',
    accessorKey: 'date',
    cell: ({ row }) => {
      return moment(row.original.date_attempted).format('MMM D, YYYY');
    },
  },
];

const filters = [
  {
    placeholder: 'Search by title',
    columnId: 'exercise_title',
    type: FilterType.TEXT,
  },
  {
    placeholder: 'Filter by type',
    columnId: 'type',
    type: FilterType.SELECT,
    options: [
      {
        label: ExerciseTypeMap[ExerciseType.MultipleChoiceCloze],
        value: ExerciseType.MultipleChoiceCloze,
      },
      {
        label: ExerciseTypeMap[ExerciseType.Reading],
        value: ExerciseType.Reading,
      },
      {
        label: ExerciseTypeMap[ExerciseType.Writing],
        value: ExerciseType.Writing,
      },
      {
        label: ExerciseTypeMap[ExerciseType.Custom],
        value: ExerciseType.Custom,
      },
    ],
  },
];

const AttemptCard = ({ row, onClickRow }) => (
  <div
    key={row.id}
    onClick={() => onClickRow?.(row)}
    className={`${onClickRow && 'cursor-pointer'} flex flex-col p-4 border-b hover:bg-slate-100`}
  >
    <div className='flex justify-between items-center'>
      <div>
        <p className='font-bold'>{row.renderValue('exercise_title')}</p>
        <p className='text-sm'>{ExerciseTypeMap[row.original.exercise_type]}</p>
      </div>
      <ScoreBadge
        score={Math.round(row.original.score * 100)}
        className='text-xs max-w-14'
      />
    </div>
    <p className='text-xs text-slate-500'>
      {moment(row.original.date_attempted).format('MMM D, YYYY')}
    </p>
  </div>
);

const ExercisesByStudentTable = ({ attempts }) => {
  const navigate = useNavigate();

  const onRowClick = row => {
    navigate(`/students/${row.original.user_id}/attempts/${row.original.id}`);
  };

  return (
    <DataTableWithPagination
      columns={columns}
      data={attempts}
      onClickRow={onRowClick}
      filters={filters}
      customCard
      card={AttemptCard}
    />
  );
};

export default ExercisesByStudentTable;
