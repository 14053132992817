import Loader from 'components/common/Loader';
import { Badge } from 'components/ui/badge';
import { DataTable } from 'components/ui/data-table';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

const columns = [
  {
    header: 'Title',
    accessorKey: 'title',
  },
];

const ExercisesTable = ({
  currentClassrooms = [],
  debounceTitle,
  useGetAllExercises,
}) => {
  const navigate = useNavigate();

  const {
    data: exercises,
    isLoading,
    isSuccess,
    page,
    pageCount,
    setPage,
  } = useGetAllExercises({ title: debounceTitle });

  const onClickRow = row => {
    navigate(`/${row.original.type}/${row.original.id}`);
  };

  const columnsWithClassrooms = useMemo(
    () => [
      ...columns,
      {
        id: 'activeClassrooms',
        header: () => (
          <div className='flex justify-center'>Active classrooms</div>
        ),
        cell: ({ row }) => {
          const activeClassrooms = row.original.active_classrooms || [];

          if (activeClassrooms?.length === currentClassrooms?.length) {
            return (
              <div className='flex justify-center'>
                <Badge>All</Badge>
              </div>
            );
          }

          if (activeClassrooms.length === 0) {
            return (
              <div className='flex justify-center'>
                <Badge variant='outline'>None</Badge>
              </div>
            );
          }

          return (
            <div className='flex justify-center gap-2'>
              {activeClassrooms.map(classroom => (
                <Badge key={classroom.id} variant='secondary'>
                  {classroom.name}
                </Badge>
              ))}
            </div>
          );
        },
      },
    ],
    [currentClassrooms],
  );

  if (isLoading) {
    return <Loader />;
  }

  if (!isSuccess) {
    return <div>Error loading exercises</div>;
  }

  return (
    <DataTable
      columns={columnsWithClassrooms}
      data={exercises}
      onClickRow={onClickRow}
      page={page}
      pageCount={pageCount}
      setPage={setPage}
    />
  );
};

export default ExercisesTable;
