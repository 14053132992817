// import { DataTable } from 'components/ui/data-table';
import { DataTableWithPagination } from 'components/ui/data-table-with-pagination';

const columns = [
  {
    header: 'Teacher',
    accessorKey: 'name',
  },
  {
    header: 'Email',
    accessorKey: 'email',
  },
];

const TeachersTable = ({ teachers }) => {
  return (
    <DataTableWithPagination
      columns={columns}
      data={teachers}
      rowsPerPage={5}
    />
  );
};

export default TeachersTable;
