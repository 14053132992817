import { Radio, Select, Button, Card } from 'antd';
import Banner from 'components/common/Banner';
import reactStringReplace from 'react-string-replace';
import { TypographyH2 } from 'components/ui/typography/typographyH2';

const generateDropdownOptions = options =>
  options.map(option => ({
    value: option,
    label: option,
  }));

const MultipleChoiceClozeExercise = ({
  exercise,
  optionsValue,
  onChangeOption,
  validateAnswers,
  exerciseCompleted,
}) => {
  return (
    <>
      <Banner
        title='Multiple Choice Cloze'
        description='Read the text below and decide which answer (A, B, C or D) best fits each gap. There is an example at the beginning (0).'
      />

      <Card className='md:w-fit'>
        <div className='flex gap-2 flex-col sm:flex-row px-4'>
          <span className='font-bold mr-4 text-lg'>Example - Gap 0</span>
          {exercise.options[0].map(option => (
            <Radio
              defaultChecked={option === exercise.answers[0]}
              disabled
              key={option}
              className='flex items-center'
            >
              {option}
            </Radio>
          ))}
        </div>
      </Card>
      <div className='flex justify-between flex-col gap-4 mt-6'>
        <div className='leading-[3rem] w-fit space-y-2'>
          <TypographyH2>{exercise.title}</TypographyH2>
          <div>
            {exercise.exercise.map((sentence, index) => (
              <p key={index}>
                {reactStringReplace(sentence, /{gap_(\d+)}/g, match => {
                  const options = generateDropdownOptions(
                    exercise.options[match],
                  );
                  const isExample = match === '0';

                  if (isExample) {
                    return (
                      <Select
                        style={{ width: 150 }}
                        disabled
                        defaultValue={exercise.answers[0]}
                      />
                    );
                  }

                  return (
                    <Select
                      placeholder='Select an option'
                      style={{ width: 150 }}
                      onChange={value => onChangeOption(value, match)}
                      options={options}
                      value={optionsValue[match] || null}
                      disabled={exerciseCompleted}
                    />
                  );
                })}
              </p>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default MultipleChoiceClozeExercise;
