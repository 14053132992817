import apiClient from 'api/apiClient';

export const getUserAttempts = async userId => {
  const token = localStorage.getItem('token');

  const response = await apiClient.get(`/attempts?user_id=${userId}`, {
    headers: {
      Authorization: token,
    },
  });

  return response.data;
};

export const getAttempt = async attemptId => {
  const token = localStorage.getItem('token');

  const response = await apiClient.get(`/attempts/${attemptId}`, {
    headers: {
      Authorization: token,
    },
  });

  return response.data;
};
