import { FilterType } from 'components/ui/data-table';
import { DataTableWithPagination } from 'components/ui/data-table-with-pagination';
import ChatHistory from 'components/ChatHistory';
import { useState } from 'react';

const columns = [
  {
    header: 'Title',
    accessorKey: 'exercise_title',
    id: 'exercise_title',
  },
];

const filters = [
  {
    placeholder: 'Search by title',
    columnId: 'exercise_title',
    type: FilterType.TEXT,
  },
];

const UncompletedExercisesTable = ({ attempts }) => {
  const [chatOpen, setChatOpen] = useState(false);
  const [currentMessages, setCurrentMessages] = useState([]);

  const onRowClick = row => {
    const { messages } = row.original;
    setCurrentMessages(messages.length > 0 ? messages : []);
    setChatOpen(true);
  };

  return (
    <>
      <ChatHistory
        messages={currentMessages}
        open={chatOpen}
        setOpen={setChatOpen}
      />
      <DataTableWithPagination
        columns={columns}
        data={attempts}
        onClickRow={onRowClick}
        filters={filters}
      />
    </>
  );
};

export default UncompletedExercisesTable;
