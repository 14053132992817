import { useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from '@uidotdev/usehooks';
import { Button, Radio, Space, message, Card, Modal } from 'antd';
import { BulbOutlined } from '@ant-design/icons';
import { getHint } from 'api/exercises';
import Banner from 'components/common/Banner';
import { TypographyH4 } from 'components/ui/typography/typographyH4';
import { TypographyH2 } from 'components/ui/typography/typographyH2';
import { TypographyH3 } from 'components/ui/typography/typographyH3';

const ReadingExercise = ({
  exercise,
  optionsValue,
  onChangeOption,
  exerciseCompleted,
}) => {
  const isMobile = useMediaQuery('only screen and (max-width : 480px)');
  const [isLoadingHint, setIsLoadingHint] = useState(false);
  const [questionHinted, setQuestionHinted] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [open, setOpen] = useState(false);
  const [currentHint, setCurrentHint] = useState({
    question: '',
    hint: '',
  });

  useEffect(() => {
    setCurrentHint({
      question: '',
      hint: '',
    });
    setOpen(false);
  }, []);

  const getQuestionHint = useCallback(
    async questionIndex => {
      setIsLoadingHint(true);
      setQuestionHinted(questionIndex);

      try {
        const { hints } = await getHint(exercise.id, questionIndex);

        if (!hints.length) {
          messageApi.error('Hint not found');
        }

        const hint = hints[0];

        const index = exercise.passage
          .toLowerCase()
          .indexOf(hint.toLowerCase());

        if (index === -1) {
          setCurrentHint({
            question: exercise.questions[questionIndex],
            hint,
          });

          return setOpen(true);
        }

        const start = Math.max(0, index - 100);
        const end = Math.min(
          exercise.passage.length,
          index + hint.length + 100,
        );
        const excerpt = exercise.passage.substring(start, end);

        setCurrentHint({
          question: exercise.questions[questionIndex],
          hint: `...${excerpt}...`,
        });

        return setOpen(true);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoadingHint(false);
      }
    },
    [exercise, messageApi],
  );

  return (
    <>
      {contextHolder}
      <Modal
        title={
          <TypographyH4 className='text-sky-500'>
            Here is your hint!
          </TypographyH4>
        }
        open={open}
        footer={null}
        centered
        onCancel={() => {
          setCurrentHint({
            question: '',
            hint: '',
          });
          setOpen(false);
        }}
      >
        <span className='text-slate-600'> {currentHint.question}</span>
        <div className='mt-2 flex flex-col'>
          <p className='font-bold'>Take a look at this part of the text:</p>
          <span>{currentHint.hint}</span>
        </div>
      </Modal>
      <Banner
        title='Reading Exercise'
        description='Read the passage and answer the questions. Choose the option that you think best fits each gap. There is an example at the beginning (0).'
      />

      <div className='flex justify-between flex-col gap-4'>
        <div className='leading-8 w-fit'>
          <TypographyH2>{exercise.title}</TypographyH2>
          <p>{exercise.passage}</p>
        </div>
        <div className='flex justify-center flex-col space-y-4'>
          <TypographyH3>Example</TypographyH3>
          <Card>
            <p className='font-bold	mb-4'>0 - {exercise.questions[0]}</p>
            <div className='flex flex-col gap-2'>
              {exercise.options[0].map(option => (
                <Radio
                  key={option}
                  defaultChecked={
                    option.trim().toLowerCase() ===
                    exercise.answers[0].trim().toLowerCase()
                  }
                  disabled
                >
                  {option}
                </Radio>
              ))}
            </div>
          </Card>
        </div>
        <div className='leading-8 w-fit space-y-4'>
          <TypographyH3>Questions</TypographyH3>
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
            {exercise.options.map(
              (options, index) =>
                index > 0 && (
                  <Card key={index} className='shadow-sm'>
                    <div className='flex gap-4 mb-4'>
                      <span className='font-bold'>
                        {index} - {exercise.questions[index]}
                      </span>
                      <Button
                        type='secondary'
                        shape='circle'
                        icon={<BulbOutlined />}
                        onClick={() => {
                          getQuestionHint(index);
                        }}
                        loading={isLoadingHint && questionHinted === index}
                        className='shadow-md hover:bg-slate-100'
                      />
                    </div>
                    <Radio.Group
                      onChange={event =>
                        onChangeOption(event.target.value, index)
                      }
                      value={optionsValue[index]}
                      size={isMobile ? 'small' : 'middle'}
                      disabled={exerciseCompleted}
                    >
                      <Space direction='vertical'>
                        {options.map(option => (
                          <Radio value={option} key={option}>
                            {option}
                          </Radio>
                        ))}
                      </Space>
                    </Radio.Group>
                  </Card>
                ),
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ReadingExercise;
