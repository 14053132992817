import CreateCustomExerciseAttemptDialog from 'components/dialogs/CreateCustomExerciseAttemptDialog';
import { DataTableWithPagination } from 'components/ui/data-table-with-pagination';

const CustomExercisesTable = ({ customExercises, classroomId }) => {
  const columns = [
    {
      header: 'Title',
      accessorKey: 'title',
    },
    {
      header: 'Actions',
      cell: ({ row }) => (
        <CreateCustomExerciseAttemptDialog
          exerciseId={row.original.id}
          classroomId={classroomId}
        />
      ),
    },
  ];

  return <DataTableWithPagination columns={columns} data={customExercises} />;
};

export default CustomExercisesTable;
