import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'components/ui/dialog';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { useForm } from 'react-hook-form';
import validator from 'lib/validator';
import { useEffect, useState } from 'react';
import { Input } from 'components/ui/input';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import {
  createCustomExercise,
  createCustomExerciseType,
} from 'api/customExercises';

const schema = validator
  .object({
    name: validator.string().required(),
  })
  .required();

const CreateCustomExerciseTypeDialog = ({ classroomId }) => {
  const [open, setOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const queryClient = useQueryClient();

  const { mutateAsync, error, isError, reset } = useMutation({
    mutationFn: createCustomExerciseType,
    onSuccess: () => {
      setOpen(false);

      queryClient.refetchQueries(['classroom-custom-exercises', classroomId]);
      messageApi.success('You have successfully created the exercise type');
    },
  });

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
    },
  });

  const onSubmit = async data => {
    try {
      await mutateAsync({ classroomId, data });
    } catch (e) {
      console.log('error', e);
    }
  };

  useEffect(() => {
    form.reset();
  }, [open, form]);

  return (
    <>
      {contextHolder}
      <Dialog
        open={open}
        onOpenChange={() => {
          setOpen(prev => !prev);
          reset();
        }}
      >
        <DialogTrigger>
          <Button variant='outline'>Create new type</Button>
        </DialogTrigger>
        <DialogContent className='flex max-h-[41rem] flex-col'>
          <DialogHeader>
            <DialogTitle>Create exercise type</DialogTitle>
            <DialogDescription>
              Create a new exercise type for your students
            </DialogDescription>
          </DialogHeader>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-6'>
              <FormField
                control={form.control}
                name='name'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Type Name</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder='Speaking' />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div
                className={`flex w-full ${isError ? 'justify-between' : 'justify-end'}`}
              >
                {isError && <p className='text-red-500'>{error.message}</p>}
                <Button type='submit'>Save</Button>
              </div>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreateCustomExerciseTypeDialog;
