import StudentStats from 'components/StudentStats';
import BackSection from 'components/common/BackSection';
import Loader from 'components/common/Loader';
import ExercisesByStudentTable from 'components/tables/ExercisesByStudentTable';
import UncompletedExercisesTable from 'components/tables/UncompletedExercises';
import { Badge } from 'components/ui/badge';
import EmptyState from 'components/ui/empty-state';
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs';
import { TypographyH1 } from 'components/ui/typography/typographyH1';
import { TypographyH3 } from 'components/ui/typography/typographyH3';
import { useGetAllUserAttempts } from 'hooks/useAttempts';
import { useGetClassroom } from 'hooks/useClassrooms';
import { useGetUncompletedExercises } from 'hooks/useExercises';
import { useGetStudent } from 'hooks/useUsers';
import { useParams } from 'react-router-dom';

const TabOptions = {
  CompletedExercises: 'Completed Exercises',
  UncompletedExercises: 'Incomplete Exercises',
};

const Student = () => {
  const { studentId } = useParams();
  const {
    data: attempts,
    isLoading: isLoadingAttempts,
    isSuccess: isAttemptSuccess,
  } = useGetAllUserAttempts(studentId);

  const {
    data: uncompletedExercises,
    isLoading: isLoadingUncompletedExercises,
    isSuccess: isUncompletedExercisesSuccess,
  } = useGetUncompletedExercises({ userId: studentId });

  const {
    data: student,
    isLoading: isLoadingUser,
    isSuccess: isStudentSuccess,
  } = useGetStudent(studentId);

  const {
    data: classroom,
    isLoading: isLoadingClassroom,
    isSuccess: isClassroomSuccess,
  } = useGetClassroom(student?.classrooms[0]);

  if (
    isLoadingAttempts ||
    isLoadingUser ||
    isLoadingClassroom ||
    isLoadingUncompletedExercises
  )
    return <Loader />;

  if (
    !isAttemptSuccess ||
    !isStudentSuccess ||
    !isUncompletedExercisesSuccess ||
    !isClassroomSuccess
  ) {
    return (
      <div className='flex flex-col gap-3 px-4 pt-6 sm:container'>
        <BackSection />
        <EmptyState message='Student not found' />
      </div>
    );
  }

  return (
    <div className='flex flex-col gap-3 px-4 py-6 sm:container space-y-2'>
      <BackSection />
      <div className='flex gap-2 items-center'>
        <TypographyH1 className='p-0 m-0'>{student.name}</TypographyH1>
        <Badge className='h-fit bg-sky-500'>{classroom.name}</Badge>
      </div>
      <StudentStats studentId={studentId} />
      <Tabs defaultValue={TabOptions.CompletedExercises}>
        <TabsList className='w-full'>
          <TabsTrigger value={TabOptions.CompletedExercises} className='w-full'>
            {TabOptions.CompletedExercises}
          </TabsTrigger>
          <TabsTrigger
            value={TabOptions.UncompletedExercises}
            className='w-full'
          >
            {TabOptions.UncompletedExercises}
          </TabsTrigger>
        </TabsList>
        <TabsContent value={TabOptions.CompletedExercises}>
          <TypographyH3 className='mb-3'>
            Completed exercises ({attempts.length})
          </TypographyH3>
          <ExercisesByStudentTable attempts={attempts} />
        </TabsContent>
        <TabsContent value={TabOptions.UncompletedExercises}>
          <TypographyH3 className='mb-3'>
            Incomplete exercises ({uncompletedExercises.length})
          </TypographyH3>
          <UncompletedExercisesTable attempts={uncompletedExercises} />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default Student;
