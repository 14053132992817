import { useForm } from 'react-hook-form';
import { Input } from 'components/ui/input';
import { Button } from 'components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { useAuth } from 'providers/AuthProvider';
import { yupResolver } from '@hookform/resolvers/yup';
import validator from 'lib/validator';
import { useState } from 'react';
import { PasswordInput } from 'components/ui/password-input';
import { Roles } from 'constants/roles';
import { Tooltip } from 'antd';
import { Info } from 'lucide-react';

const SignUpForm = ({ role }) => {
  const { signUpAction } = useAuth();
  const [error, setError] = useState(null);

  const schema = validator
    .object({
      name: validator.string().required('Name is required'),
      email: validator.string().email().required('Email is required'),
      code: validator
        .string()
        .test('code', 'Code is required for teachers', value => {
          return role === Roles.Student ? true : !!value;
        }),
      password: validator
        .string()
        .min(8, 'Password must be at least 8 characters')
        .minLowercase(1, 'Password must contain at least 1 lowercase letter')
        .minUppercase(1, 'Password must contain at least 1 uppercase letter')
        .minNumbers(1, 'Password must contain at least 1 number')
        .required('Password is required'),
      passwordConfirmation: validator
        .string()
        .oneOf([validator.ref('password'), null], 'Passwords must match'),
    })
    .required();

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
      name: '',
      code: '',
      passwordConfirmation: '',
    },
  });

  const onSubmit = async data => {
    try {
      await signUpAction({ ...data, role });
    } catch (error) {
      console.error(error);
      setError(error.message);
    }
  };

  const codeLabel =
    role === Roles.Teacher ? 'Classroom code' : 'Classroom code (Optional)';

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-6'>
        <FormField
          control={form.control}
          name='name'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name='email'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name='password'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Password</FormLabel>
              <FormControl>
                <PasswordInput {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name='passwordConfirmation'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Confirm Password</FormLabel>
              <FormControl>
                <PasswordInput {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name='code'
          render={({ field }) => (
            <FormItem>
              <FormLabel className='flex items-center gap-2'>
                <span>{codeLabel}</span>
                {role === Roles.Teacher && (
                  <Tooltip title='Use the code from one of your classrooms. You can join more classrooms once you sign up'>
                    <Info size={14} />
                  </Tooltip>
                )}
              </FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {error && <p className='mb-4 text-red-500'>{error}</p>}
        <Button type='submit' className='!mt-8 w-full'>
          Sign up
        </Button>
      </form>
    </Form>
  );
};

export default SignUpForm;
