import classNames from 'classnames';
import Loader from 'components/common/Loader';
import ScoreBadge from 'components/common/ScoreBadge';
import { Card, CardContent, CardHeader } from 'components/ui/card';
import { Separator } from 'components/ui/separator';
import { TypographyH3 } from 'components/ui/typography/typographyH3';
import { useGetExercise } from 'hooks/useExercises';

const ReadingAttempt = ({ attempt }) => {
  const {
    data: exercise,
    isLoading,
    isSuccess,
  } = useGetExercise({
    exerciseId: attempt?.exercise_id,
    type: attempt?.exercise_type,
    enabled: !!attempt,
  });

  if (isLoading) return <Loader />;

  if (!isSuccess) return <p>Error getting the exercise</p>;

  return (
    <div className='grid gap-5 md:grid-cols-2'>
      <Card>
        <CardHeader>
          <div className='flex items-center justify-between'>
            <TypographyH3 className='mb-0'>Answers and Questions</TypographyH3>
            <ScoreBadge score={attempt.score * 100} />
          </div>
        </CardHeader>
        <CardContent>
          <div className='flex flex-col gap-4'>
            {exercise.questions.map((question, index) => {
              if (index === 0) return null;

              return (
                <div key={index}>
                  <Separator />
                  <div className='mt-4'>
                    <p className='mb-3 font-bold'>{question}</p>
                    <ul>
                      {exercise.options[index].map((option, i) => {
                        const isSelected =
                          option === attempt.user_answers[index];
                        const isCorrect = option === exercise.answers[index];

                        return (
                          <li
                            key={i}
                            className={classNames({
                              'text-red-500': isSelected && !isCorrect,
                              'text-green-500': isSelected && isCorrect,
                            })}
                          >
                            {i + 1} - {option}
                          </li>
                        );
                      })}

                      {attempt.user_answers[index] !==
                        exercise.answers[index] && (
                        <p className='mt-2 rounded bg-sky-100 p-2'>
                          <span className='min-w-32 font-semibold'>
                            Correct answer:
                          </span>{' '}
                          {exercise.options[index].indexOf(
                            exercise.answers[index],
                          ) + 1}{' '}
                          - {exercise.answers[index]}
                        </p>
                      )}
                    </ul>
                  </div>
                </div>
              );
            })}
          </div>
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <TypographyH3>{exercise.title}</TypographyH3>
        </CardHeader>
        <CardContent className='text-justify leading-7'>
          {exercise.passage}
        </CardContent>
      </Card>
    </div>
  );
};

export default ReadingAttempt;
