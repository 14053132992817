import SignInForm from 'components/forms/SignInForm';
import { Button } from 'components/ui/button';
import { Paths } from 'constants/paths';
import { useNavigate } from 'react-router-dom';

const SignInPage = () => {
  const navigate = useNavigate();

  const navigateToSignUp = () => {
    navigate(Paths.SignUp);
  };

  return (
    <>
      <div className='flex min-h-full flex-col justify-center px-6 py-12 lg:px-8'>
        <div className='flex flex-col items-center sm:mx-auto sm:w-full sm:max-w-sm'>
          <h2 className='text-md -mb-2 text-center leading-9 tracking-tight text-gray-500'>
            Welcome back to
          </h2>
          <img src='/studia-logo.png' alt='Studia Logo' className='w-60' />
        </div>
        <div className='sm:mx-auto sm:w-full sm:max-w-sm'>
          <SignInForm />
        </div>
        <p className='mt-5 text-center text-sm text-gray-500'>
          Not registered yet?
          <Button variant='link' onClick={navigateToSignUp}>
            Sign up here
          </Button>
        </p>
      </div>
    </>
  );
};

export default SignInPage;
