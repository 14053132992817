import classNames from 'classnames';
import { Card, CardContent, CardHeader } from 'components/ui/card';
import { TypographyH4 } from 'components/ui/typography/typographyH4';
import reactStringReplace from 'react-string-replace';

const ReadOnlyMultipleChoice = ({ exercise }) => {
  return (
    <div className='grid gap-5 md:grid-cols-2'>
      <Card>
        <CardHeader>
          <TypographyH4 className='mb-0'>Options and Answers</TypographyH4>
        </CardHeader>
        <CardContent>
          <div className='grid gap-3 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2'>
            {Array.from(Array(exercise.answers.length - 1).keys()).map(
              index => (
                <Card key={index} className='p-4'>
                  <p className='mb-3 font-bold'>Gap {index + 1}</p>
                  <ul>
                    {exercise.options[index + 1].map((option, i) => {
                      const isCorrect = option === exercise.answers[index + 1];

                      return (
                        <li
                          key={i}
                          className={classNames({
                            'text-green-500': isCorrect,
                          })}
                        >
                          {i + 1} - {option}
                        </li>
                      );
                    })}
                  </ul>
                </Card>
              ),
            )}
          </div>
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <TypographyH4>{exercise.title}</TypographyH4>
        </CardHeader>
        <CardContent className='text-justify leading-8'>
          {exercise.exercise.map(sentence => (
            <p key={sentence}>
              {reactStringReplace(sentence, /{gap_(\d+)}/g, match => {
                if (match === '0')
                  return (
                    <span className='border-b-2 border-dotted border-b-black'>
                      ({match}) {exercise.answers[match]}
                    </span>
                  );

                return (
                  <span className='border-b-2 border-dotted border-b-black font-bold'>
                    ({match}) {exercise.answers[match]}
                  </span>
                );
              })}
            </p>
          ))}
        </CardContent>
      </Card>
    </div>
  );
};

export default ReadOnlyMultipleChoice;
