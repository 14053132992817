import BackSection from 'components/common/BackSection';
import NewClassroomForm from 'components/forms/NewClassroomForm';
import { TypographyH2 } from 'components/ui/typography/typographyH2';
import { Paths } from 'constants/paths';
import { useNavigate } from 'react-router-dom';

const NewClassroom = () => {
  const navigate = useNavigate();

  const onBack = () => {
    navigate(Paths.Home);
  };

  return (
    <div className='px-4 py-6 sm:container'>
      <BackSection onClick={onBack} />
      <TypographyH2 className='mb-3'>New Classroom</TypographyH2>
      <NewClassroomForm />
    </div>
  );
};

export default NewClassroom;
