import apiClient from 'api/apiClient';

export const getClassroom = async classroomId => {
  const token = localStorage.getItem('token');

  const response = await apiClient.get(`classrooms/${classroomId}`, {
    headers: {
      Authorization: token,
    },
  });

  return response.data;
};

export const getClassroomsByTeacher = async teacherId => {
  const token = localStorage.getItem('token');

  const params = encodeURIComponent(teacherId);

  const response = await apiClient.get(`classrooms?teacher_id=${params}`, {
    headers: {
      Authorization: token,
    },
  });

  return response.data;
};

export const getClassrooms = async () => {
  const token = localStorage.getItem('token');

  const response = await apiClient.get('classrooms', {
    headers: {
      Authorization: token,
    },
  });

  return response.data;
};

export const createClassroom = async data => {
  const token = localStorage.getItem('token');

  const response = await apiClient.post('classrooms', data, {
    headers: {
      Authorization: token,
    },
  });

  return response.data;
};

export const getClassroomStats = async classroomId => {
  const token = localStorage.getItem('token');

  const response = await apiClient.get(
    `/attempts/statistics?classroom_id=${classroomId}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};
