export const Paths = {
  Home: '/',
  MultipleChoiceCloze: '/multiple-choice-cloze',
  Reading: '/reading',
  SignUp: '/auth/sign-up',
  SignIn: '/auth/sign-in',
  Student: '/students/:studentId',
  Attempt: '/students/:studentId/attempts/:attemptId',
  Classroom: '/classrooms/:classroomId',
  NewClassroom: '/classrooms/new',
  Exercises: '/exercises',
  MultipleChoiceClozeExercise: '/multiple_choice_fce/:exerciseId',
  ReadingExercise: '/reading_camla/:exerciseId',
  WritingExercise: '/writing/:exerciseId',
  ClassroomCustomExercises:
    '/classrooms/:classroomId/custom-exercises/:customTypeId',
  Writing: '/writing',
  Attempts: '/attempts',
};
