import { useCallback, useEffect, useRef, useState } from 'react';
import { Alert, Button, message } from 'antd';
import Loader from 'components/common/Loader';
import { TypographyH2 } from 'components/ui/typography/typographyH2';
import Chat from 'components/Chat';
import { getExerciseExplanation, getUnattemptedExercise } from 'api/exercises';
import { useQueryClient } from '@tanstack/react-query';
import { useAuth } from 'providers/AuthProvider';

const ExerciseWrapper = ({ exercise: ExerciseComponent, exerciseType }) => {
  const [messages, setMessages] = useState([]);
  const [exercise, setExercise] = useState(null);
  const [optionsValue, setOptionsValue] = useState({});
  const [answerValidation, setAnswerValidation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [exerciseCompleted, setExerciseCompleted] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [points, setPoints] = useState(0);
  const [messageApi, contextHolder] = message.useMessage();
  const [chatSessionId, setChatSessionId] = useState(null);
  const answersRef = useRef(null);
  const queryClient = useQueryClient();

  const fetchExercise = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getUnattemptedExercise(exerciseType);

      setExercise(response);

      setOptionsValue(
        response.options.reduce((acc, _, index) => {
          if (index > 0) acc[index] = '';

          return acc;
        }, {}),
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchExercise();
    setAnswerValidation([]);
    setExerciseCompleted(false);
    setPoints(0);
  }, []);

  const onChangeOption = (value, index) => {
    setOptionsValue(prev => ({ ...prev, [index]: value }));
  };

  const onNextExercise = () => {
    setAnswerValidation([]);
    setExerciseCompleted(false);
    setPoints(0);
    setMessages([]);

    fetchExercise();
  };

  const validateAnswers = useCallback(async () => {
    const answers = Object.values(optionsValue);

    if (answers.some(answer => !answer)) {
      messageApi.open({
        type: 'error',
        content: 'Please fill all the answers',
      });
      return;
    }

    setIsValidating(true);

    const exerciseToValidate = {
      exerciseId: exercise.id,
      answers: { response: [exercise.answers[0], ...answers] },
      exerciseType,
    };

    try {
      const { points, explanations } =
        await getExerciseExplanation(exerciseToValidate);

      const validation = [];

      for (let i = 1; i < exercise.options.length; i++) {
        const isCorrect = optionsValue[i] === exercise.answers[i];
        const explanation = explanations.find(element => element[i]);

        validation.push({
          index: i,
          isCorrect,
          explanation: !isCorrect ? explanation[i] : '',
        });
      }

      setAnswerValidation(validation);
      setExerciseCompleted(true);
      setPoints(points);

      await queryClient.invalidateQueries({ queryKey: ['attempts'] });
    } catch (error) {
      console.error(error);
      messageApi.open({
        type: 'error',
        content: 'Something went wrong. Please try again.',
      });
    } finally {
      setIsValidating(false);
    }
  }, [exercise, optionsValue]);

  useEffect(() => {
    if (exerciseCompleted) {
      answersRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [exerciseCompleted]);

  if (isLoading) return <Loader />;

  if (!exercise) return null;

  if (isValidating) return <Loader />;

  return (
    <>
      {
        <div className='px-4 py-6 sm:container space-y-8'>
          <ExerciseComponent
            exercise={exercise}
            validateAnswers={validateAnswers}
            optionsValue={optionsValue}
            onChangeOption={onChangeOption}
            exerciseCompleted={exerciseCompleted}
            isValidating={isValidating}
            onNextExercise={onNextExercise}
          />
        </div>
      }
      <Chat
        messages={messages}
        exercise={exercise}
        exerciseType={exerciseType}
        setMessages={setMessages}
        sessionId={chatSessionId}
        setSessionId={setChatSessionId}
      />
      {contextHolder}
      {exerciseCompleted && (
        <div className='container p-4 sm:p-6 flex flex-col' ref={answersRef}>
          <TypographyH2>Let&apos;s check your answers!</TypographyH2>
          <p>
            {points
              ? `Good job! You have ${points} correct answers`
              : 'No correct answers this time, but try again!'}
          </p>
          <div className='grid gap-4 sm:grid-cols-2 md:grid-cols-3 mt-4'>
            {answerValidation.map((answer, index) => {
              const message = answer.isCorrect
                ? `${index + 1} - You got it!`
                : `${index + 1} - Almost there. ${answer.explanation} `;
              return (
                <Alert
                  message={message}
                  key={index}
                  type={answer.isCorrect ? 'success' : 'error'}
                />
              );
            })}
          </div>
        </div>
      )}
      <footer className='border-t border-slate-300'>
        <div className='px-4 py-6 sm:container flex flex-col sm:flex-row justify-end gap-4 w-full '>
          <Button className='h-10 min-w-40' onClick={onNextExercise}>
            {exerciseCompleted ? 'Next Exercise' : 'Skip Exercise'}
          </Button>
          {!exerciseCompleted && (
            <Button
              className='h-10 min-w-40'
              type='primary'
              onClick={validateAnswers}
              disabled={isValidating || exerciseCompleted}
            >
              Check Answers
            </Button>
          )}
        </div>
      </footer>
    </>
  );
};

export default ExerciseWrapper;
