import BackSection from 'components/common/BackSection';
import Loader from 'components/common/Loader';
import CreateCustomExerciseDialog from 'components/dialogs/CreateCustomExerciseDialog';
import CustomExercisesTable from 'components/tables/CustomExercisesTable';
import { TypographyH1 } from 'components/ui/typography/typographyH1';
import {
  useGetCustomType,
  useGetCustomTypeExercisesByClassroom,
} from 'hooks/useCustomExercise';
import { useParams } from 'react-router-dom';

const CustomExercise = () => {
  const { classroomId, customTypeId } = useParams();
  const {
    data: customExercises,
    isSuccess,
    isLoading,
  } = useGetCustomTypeExercisesByClassroom({ customTypeId, classroomId });

  const {
    data: customType,
    isSuccess: customTypeIsSuccess,
    isLoading: customTypeIsLoading,
  } = useGetCustomType(customTypeId);

  if (isLoading || customTypeIsLoading) {
    return <Loader />;
  }

  if (!isSuccess || !customTypeIsSuccess) {
    return <div>Failed to load custom exercise</div>;
  }

  const customExerciseType = customType.name;

  return (
    <div className='flex flex-col gap-3 px-4 pt-6 sm:container'>
      <BackSection />
      <div className='flex w-full justify-between'>
        <TypographyH1 className='mb-3'>{customExerciseType}</TypographyH1>
        <CreateCustomExerciseDialog
          classroomId={classroomId}
          exerciseTypeId={customTypeId}
          customExerciseTypeName={customExerciseType}
        />
      </div>
      <CustomExercisesTable
        customExercises={customExercises}
        classroomId={classroomId}
      />
    </div>
  );
};

export default CustomExercise;
