import { FloatButton } from 'antd';
import { MessageOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { Drawer } from 'antd';
import { Button as ButtonAnt } from 'antd';
import { sendMessageToChat } from 'api/chat';
import Message from './Message';
import { Input } from 'components/ui/input';
import { Button } from 'components/ui/button';
import { Form, FormControl, FormField } from 'components/ui/form';
import PulseLoader from 'react-spinners/PulseLoader';

const Chat = ({
  messages,
  setMessages,
  exercise,
  exerciseType,
  sessionId,
  setSessionId,
}) => {
  const [open, setOpen] = useState(false);
  const messagesContainerRef = useRef(null);
  const [isWaiting, setIsWaiting] = useState(false);

  const form = useForm({
    defaultValues: {
      message: '',
    },
  });

  const toggleOpen = () => {
    setOpen(currentValue => !currentValue);
  };

  const scrollToBottom = () => {
    // This is a workaround to scroll to the bottom of the chat. It needs to be this way for the Drawer component
    if (open && document.body.querySelector('.ant-drawer-body'))
      document.body.querySelector('.ant-drawer-body').scrollTo(0, 9999);
  };

  useEffect(() => {
    scrollToBottom();
  }, [open, messages]);

  useEffect(() => {
    if (messages.length === 0)
      setMessages(prevMessages => [
        ...prevMessages,
        {
          message: `Hello and welcome! I am here to help you with your English exercise on ${exercise.title}. Feel free to ask me anything related to grammar, vocabulary, writing tips, or any other English language queries. Let's get started!`,
          options: [
            'Explain the topic of the exercise',
            'What are some key vocabulary words in this exercise?',
            'How do I complete a question?',
          ],
          sender: 'bot',
          date: new Date(),
        },
      ]);
  }, []);

  const initChat = async messageText => {
    const response = await sendMessageToChat({
      message: messageText,
      exercise_id: exercise.id,
      exercise_type: exerciseType,
      exercise_title: exercise.title,
    });

    setSessionId(response.session_id);

    return response;
  };

  const sendMessage = async ({ message: currentMessage }) => {
    form.reset({ message: '' });

    if (!currentMessage) {
      return;
    }

    setMessages(prevMessages => [
      ...prevMessages,
      {
        message: currentMessage,
        sender: 'user',
        date: new Date(),
      },
    ]);

    setIsWaiting(true);

    try {
      let response = null;

      if (messages.length === 1) {
        response = await initChat(currentMessage);
      } else {
        response = await sendMessageToChat({
          session_id: sessionId,
          message: currentMessage,
          exercise_id: exercise.id,
          exercise_type: exerciseType,
          exercise_title: exercise.title,
        });
      }

      setIsWaiting(false);

      setMessages(prevMessages => [
        ...prevMessages,
        {
          message: response.message,
          sender: 'bot',
          date: new Date(),
        },
      ]);
    } catch (error) {
      setIsWaiting(false);

      setMessages(prevMessages => [
        ...prevMessages,
        {
          message:
            'I am sorry, I am out of service right now :( Try again soon!',
          sender: 'bot',
          date: new Date(),
        },
      ]);
    }
  };

  return (
    <>
      <FloatButton
        className={classNames({
          hidden: open,
        })}
        onClick={() => setOpen(true)}
        icon={<MessageOutlined style={{ fontSize: 22 }} />}
        style={{ width: 60, height: 60 }}
        badge={{ dot: true, color: '#0ea5e9' }}
      />
      <Drawer
        open={open}
        onClose={toggleOpen}
        closable={false}
        title='Chatbot'
        styles={{
          header: { padding: '20px 24px' },
        }}
        width={500}
        footer={
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(sendMessage)}
              className='flex w-full items-center gap-2 p-2'
            >
              <FormField
                control={form.control}
                name='message'
                render={({ field }) => (
                  <FormControl>
                    <Input {...field} placeholder='Type a message...' />
                  </FormControl>
                )}
              />
              <Button type='submit' className='mt-0'>
                Send
              </Button>
            </form>
          </Form>
        }
        extra={
          <ButtonAnt
            type='text'
            shape='circle'
            icon={<CloseCircleOutlined style={{ fontSize: '20px' }} />}
            onClick={toggleOpen}
          />
        }
      >
        <div
          className='flex flex-col gap-5 overflow-y-auto'
          ref={messagesContainerRef}
        >
          {messages.map(message => (
            <Message key={message.id} {...message} onClickOption={sendMessage}>
              {message.message}
            </Message>
          ))}
          {isWaiting && (
            <Message sender='bot'>
              <div className='flex justify-center'>
                <PulseLoader color='#0ea5e9' size={6} />
              </div>
            </Message>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default Chat;
