import { useNavigate } from 'react-router-dom';
import { Card } from 'antd';
import { EXERCISES, ExerciseType } from 'constants/exercises';
import { TypographyH2 } from 'components/ui/typography/typographyH2';
import { BookOpenCheck, ListTodo, NotebookPen } from 'lucide-react';

const ExerciseIcon = {
  [ExerciseType.MultipleChoiceCloze]: <ListTodo color='white' />,
  [ExerciseType.Reading]: <BookOpenCheck color='white' />,
  [ExerciseType.Writing]: <NotebookPen color='white' />,
};

const Menu = () => {
  const navigate = useNavigate();

  const handleClickOption = to => {
    navigate(to);
  };

  return (
    <div className='px-4 sm:px-6 pt-2 space-y-4'>
      <div>
        <TypographyH2>Available Exercises</TypographyH2>
        <p className='text-md font-light -mt-2 text-slate-500'>
          Choose one of the exercises and start learning
        </p>
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2	lg:grid-cols-3 gap-4'>
        {EXERCISES.map(exercise => (
          <Card
            key={exercise.path}
            onClick={() => handleClickOption(exercise.path)}
            className='shadow-lg hover:shadow-lg cursor-pointer h-full'
            classNames={{
              body: 'h-full',
            }}
          >
            <div className='flex gap-4 items-center h-full'>
              <div className='bg-[#0ea5e999] p-3 rounded-xl'>
                {ExerciseIcon[exercise.type]}
              </div>
              <div>
                <p className='text-base mb-1 font-semibold'>{exercise.title}</p>
                <p className='text-sm text-slate-500'>{exercise.description}</p>
              </div>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default Menu;
