import { getExercise } from 'api/exercises';
import classNames from 'classnames';
import Loader from 'components/common/Loader';
import ScoreBadge from 'components/common/ScoreBadge';
import { Card, CardContent, CardHeader } from 'components/ui/card';
import { TypographyH4 } from 'components/ui/typography/typographyH4';
import { useGetExercise } from 'hooks/useExercises';
import reactStringReplace from 'react-string-replace';

const MultipleChoiceAttempt = ({ attempt }) => {
  const {
    data: exercise,
    isLoading,
    isSuccess,
  } = useGetExercise({
    exerciseId: attempt?.exercise_id,
    type: attempt?.exercise_type,
    enabled: !!attempt,
  });

  if (isLoading) return <Loader />;

  if (!isSuccess) return <p>Error getting the exercise</p>;

  return (
    <div className='grid gap-5 md:grid-cols-2'>
      <Card>
        <CardHeader>
          <div className='flex items-center justify-between'>
            <TypographyH4 className='mb-0'>Answers</TypographyH4>
            <ScoreBadge score={attempt.score * 100} />
          </div>
        </CardHeader>
        <CardContent>
          <div className='grid gap-3 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2'>
            {Array.from(Array(exercise.answers.length - 1).keys()).map(
              index => (
                <Card key={index} className='p-4'>
                  <p className='mb-3 font-bold'>Gap {index + 1}</p>
                  <ul>
                    {exercise.options[index + 1].map((option, i) => {
                      const isSelected =
                        option === attempt.user_answers[index + 1];
                      const isCorrect = option === exercise.answers[index + 1];

                      return (
                        <li
                          key={i}
                          className={classNames({
                            'text-red-500': isSelected && !isCorrect,
                            'text-green-500': isSelected && isCorrect,
                          })}
                        >
                          {i + 1} - {option}
                        </li>
                      );
                    })}

                    {attempt.user_answers[index + 1] !==
                      exercise.answers[index + 1] && (
                      <p className='mt-2 rounded bg-sky-100 p-2'>
                        <span className='min-w-32 font-semibold'>
                          Correct answer:
                        </span>{' '}
                        {exercise.options[index + 1].indexOf(
                          exercise.answers[index + 1],
                        ) + 1}{' '}
                        - {exercise.answers[index + 1]}
                      </p>
                    )}
                  </ul>
                </Card>
              ),
            )}
          </div>
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <TypographyH4>{attempt.exercise_title}</TypographyH4>
        </CardHeader>
        <CardContent className='text-justify leading-8'>
          {exercise.exercise.map(sentence => (
            <p key={sentence}>
              {reactStringReplace(sentence, /{gap_(\d+)}/g, match => {
                if (match === '0')
                  return (
                    <span className='border-b-2 border-dotted border-b-black'>
                      ({match}) {exercise.answers[match]}
                    </span>
                  );

                return (
                  <span className='border-b-2 border-dotted border-b-black font-bold'>
                    ({match}) {exercise.answers[match]}
                  </span>
                );
              })}
            </p>
          ))}
        </CardContent>
      </Card>
    </div>
  );
};

export default MultipleChoiceAttempt;
