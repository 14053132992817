import classNames from 'classnames';
import { Badge } from 'components/ui/badge';

const ScoreBadge = ({ score, className }) => {
  return (
    <Badge
      className={classNames(
        'flex w-24 justify-center text-base',
        {
          'bg-red-500': score < 50,
          'bg-yellow-500': score >= 50 && score < 70,
          'bg-green-500': score >= 70,
        },
        className,
      )}
    >
      {score}%
    </Badge>
  );
};

export default ScoreBadge;
