import { cn } from 'lib/utils';

export function TypographyH1({ children, className }) {
  return (
    <h1
      className={cn(
        'mb-4 scroll-m-20 text-[2.5rem] font-extrabold tracking-tight',
        className,
      )}
    >
      {children}
    </h1>
  );
}
