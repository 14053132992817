import {
  createBrowserRouter,
  RouterProvider as RouterProviderReactRouter,
} from 'react-router-dom';

import { ROUTES } from 'constants/routes';

const RouterProvider = () => {
  const router = createBrowserRouter(ROUTES);

  return <RouterProviderReactRouter router={router} />;
};

export default RouterProvider;
