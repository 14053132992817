import apiClient from './apiClient';

export const signUp = async ({ email, name, password, code, role }) => {
  const response = await apiClient.post('auth/sign-up', {
    email,
    name,
    password,
    code,
    role,
  });

  return response.data;
};

export const signIn = async ({ email, password }) => {
  const response = await apiClient.post('/auth/sign-in', { email, password });

  return response.data;
};
