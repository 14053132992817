import ScoreBadge from 'components/common/ScoreBadge';
import { Card, CardContent, CardHeader } from 'components/ui/card';
import { Separator } from 'components/ui/separator';
import { TypographyH3 } from 'components/ui/typography/typographyH3';

const CustomAttempt = ({ attempt }) => {
  return (
    <div className='gap-5'>
      <Card>
        <CardHeader>
          <div className='flex items-center justify-between'>
            <TypographyH3 className='mb-0'>Score</TypographyH3>
            <ScoreBadge score={Math.round(attempt.score * 100)} />
          </div>
        </CardHeader>
        <CardContent>
          <div className='flex flex-col gap-4'>
            <Separator />
            <div className='mt-4'>
              <p className='mb-3 font-bold'>Notes</p>
              <p>{attempt.notes || 'No notes in this attempt'}</p>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default CustomAttempt;
