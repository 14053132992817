import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';

export default function SelectInput({
  control,
  name,
  label,
  items,
  placeholder,
  disabled = false,
  valueAccessor,
  keyAccessor = valueAccessor,
  labelAccessor = valueAccessor,
  onValueChange,
}) {
  return (
    <FormField
      control={control}
      name={name}
      disabled={disabled}
      render={({ field }) => (
        <FormItem>
          {label && <FormLabel>{label}</FormLabel>}
          <Select
            disabled={disabled}
            onValueChange={value => {
              field.onChange(value);

              return onValueChange?.(value);
            }}
            defaultValue={field.value}
          >
            <FormControl>
              <SelectTrigger>
                <SelectValue placeholder={placeholder} />
              </SelectTrigger>
            </FormControl>
            <SelectContent>
              {items.map(d => (
                <SelectItem key={keyAccessor(d)} value={valueAccessor(d)}>
                  {labelAccessor(d)}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
