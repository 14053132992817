import apiClient from 'api/apiClient';

export const getClassroomCustomExercises = async classroomId => {
  const token = localStorage.getItem('token');

  const response = await apiClient.get(
    `/exercises/custom/subtypes?classroom_id=${classroomId}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

export const createCustomExerciseType = async ({ classroomId, data }) => {
  const token = localStorage.getItem('token');

  const response = await apiClient.post(
    `/exercises/custom/subtypes?classroom_id=${classroomId}`,
    data,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

export const createCustomExercise = async ({ classroomId, data }) => {
  const token = localStorage.getItem('token');

  const response = await apiClient.post(
    `/exercises/custom?classroom_id=${classroomId}`,
    {
      exercise_type_id: data.exerciseTypeId,
      title: data.title,
    },
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

export const getCustomTypeExercisesByClassroom = async ({
  customTypeId,
  classroomId,
}) => {
  const token = localStorage.getItem('token');

  const response = await apiClient.get(
    `/exercises/custom?subtype=${customTypeId}&classroom_id=${classroomId}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

export const createCustomExerciseAttempt = async ({ exerciseId, data }) => {
  const token = localStorage.getItem('token');

  const response = await apiClient.post(
    `/exercises/custom/${exerciseId}/correction`,
    {
      ...data,
      student_id: data.studentId,
    },
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

export const getCustomType = async customTypeId => {
  const token = localStorage.getItem('token');

  const response = await apiClient.get(
    `exercises/custom/subtypes/${customTypeId}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};
