import BounceLoader from 'react-spinners/BounceLoader';

import classes from './Loader.module.css';

const Loader = () => (
  <div className={classes.root}>
    <BounceLoader color='#0ea5e9' />
  </div>
);

export default Loader;
