import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'antd';
import Chat from 'components/Chat';
import Banner from 'components/common/Banner';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { Textarea } from 'components/ui/textarea';
import { TypographyH2 } from 'components/ui/typography/typographyH2';
import { TypographyH3 } from 'components/ui/typography/typographyH3';
import { ExerciseType } from 'constants/exercises';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import validator from 'lib/validator';
import { getExerciseExplanation, getUnattemptedExercise } from 'api/exercises';
import Loader from 'components/common/Loader';
import { TypographyH4 } from 'components/ui/typography/typographyH4';
import ScoreBadge from 'components/common/ScoreBadge';
import { BicepsFlexed, ChartNoAxesColumnIncreasing } from 'lucide-react';

const schema = validator
  .object({
    writing: validator
      .string()
      .required('You need to write an answer')
      .min(300, 'Your answer is too short. Try to write a little bit more.'),
  })
  .required();

const WritingExercise = () => {
  const [messages, setMessages] = useState([]);
  const [exercise, setExercise] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [chatSessionId, setChatSessionId] = useState(null);
  const [isValidating, setIsValidating] = useState(false);
  const [exerciseCompleted, setExerciseCompleted] = useState(false);
  const [answerValidation, setAnswerValidation] = useState(null);
  const answersRef = useRef(null);

  const fetchExercise = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getUnattemptedExercise(ExerciseType.Writing);

      setExercise(response);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchExercise();
  }, []);

  const onNextExercise = () => {
    setMessages([]);
    fetchExercise();
    setAnswerValidation(null);
    setExerciseCompleted(false);
    form.reset();
  };

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      writing: '',
    },
  });

  if (isLoading || isValidating) return <Loader />;

  if (!exercise) return null;

  const onSubmit = async data => {
    setIsValidating(true);

    try {
      const validation = await getExerciseExplanation({
        exerciseId: exercise.id,
        exerciseType: ExerciseType.Writing,
        answers: { response: [data.writing] },
      });

      setAnswerValidation(validation);
      setExerciseCompleted(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsValidating(false);
    }
  };

  return (
    <>
      <Chat
        messages={messages}
        exercise={exercise}
        exerciseType={ExerciseType.Writing}
        setMessages={setMessages}
        sessionId={chatSessionId}
        setSessionId={setChatSessionId}
      />
      <div className='px-4 py-6 sm:container space-y-8'>
        <Banner
          title='Writing Exercise'
          description='Read the given text, respond with a letter or essay according to the task. Consider the tips and questions provided. There is no word limit but aim to write between 100 and 250 words.'
        />
        <div className='flex gap-6 flex-col'>
          <div className='flex flex-col md:flex-row gap-6'>
            <div className='leading-8 bg-paper-texture rounded-lg p-6 font-serif shadow-md md:w-2/3 lg:w-3/4'>
              <TypographyH2>{exercise.title}</TypographyH2>
              {exercise.text.map((text, index) => (
                <p key={index}>{text}</p>
              ))}
            </div>
            <div className='lg:w-1/4 md:w-1/3'>
              <div className='leading-8 h-full space-y-4 md:pt-6'>
                <TypographyH3>Here are some tips to help you!</TypographyH3>
                <ol className='list-disc ml-4'>
                  {exercise.tips.map((hint, index) => (
                    <li key={index}>{hint}</li>
                  ))}
                </ol>
              </div>
            </div>
          </div>
          <div className='leading-8 w-fit space-y-4'>
            <div className='space-y-2'>
              <TypographyH3>Your task</TypographyH3>
              <p className='text-gray-500 mb-7'>{exercise.task}</p>
            </div>
            <Form {...form}>
              <FormField
                control={form.control}
                name='writing'
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Textarea
                        {...field}
                        className='h-48'
                        placeholder='Write here...'
                        disabled={exerciseCompleted}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </Form>
          </div>
        </div>
      </div>
      {exerciseCompleted && answerValidation && (
        <div
          className='container p-4 sm:p-6 flex flex-col space-y-4'
          ref={answersRef}
        >
          <div className='flex justify-between items-center'>
            <TypographyH2 className='p-0'>
              Let&apos;s check your answer!
            </TypographyH2>
            <ScoreBadge
              score={answerValidation.points}
              className='w-20 md:w-24'
            />
          </div>
          <div className='flex gap-4 flex-col sm:flex-row'>
            <div className='p-6 space-y-2 shadow-sm sm:w-1/2 rounded-lg bg-slate-100'>
              <div className='flex gap-2 items-center'>
                <BicepsFlexed size={18} />
                <TypographyH4 className='m-0'>Strengths</TypographyH4>
              </div>
              <p>
                {answerValidation.explanations[0].strengths ||
                  'No strengths to highlight this time but there is opportunity for improvement'}
              </p>
            </div>
            <div className='p-6 space-y-2 bg-slate-100 sm:w-1/2 rounded-lg shadow-sm'>
              <div className='flex gap-2 items-center'>
                <ChartNoAxesColumnIncreasing size={18} />
                <TypographyH4 className='m-0'>Improvements</TypographyH4>
              </div>
              <p>
                {answerValidation.explanations[0].improvements ||
                  'Awesome! No improvements this time.'}
              </p>
            </div>
          </div>
        </div>
      )}
      <footer className='border-t border-slate-300'>
        <div className='px-4 py-6 sm:container flex flex-col sm:flex-row justify-end gap-4 w-full '>
          <Button className='h-10 min-w-40' onClick={onNextExercise}>
            {exerciseCompleted ? 'Next Exercise' : 'Skip Exercise'}
          </Button>
          {!exerciseCompleted && (
            <Button
              className='h-10 min-w-40'
              type='primary'
              onClick={form.handleSubmit(onSubmit)}
            >
              Send answer
            </Button>
          )}
        </div>
      </footer>
    </>
  );
};

export default WritingExercise;
