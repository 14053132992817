const BannerBackground = ({ className }) => {
  return (
    <svg
      width='412'
      height='284'
      viewBox='0 0 412 284'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M51.6285 -30.3715V79.3391H161.339V-30.3715H51.6285ZM32.2678 -82C14.4468 -82 0 -67.5532 0 -49.7322V98.6997C0 116.521 14.4468 130.968 32.2678 130.968H180.7C198.521 130.968 212.968 116.521 212.968 98.6997V-49.7322C212.968 -67.5532 198.521 -82 180.7 -82H32.2678Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M303.318 -30.3715V79.3391H413.028V-30.3715H303.318ZM283.957 -82C266.136 -82 251.689 -67.5532 251.689 -49.7322V98.6997C251.689 116.521 266.136 130.968 283.957 130.968H432.389C450.21 130.968 464.657 116.521 464.657 98.6997V-49.7322C464.657 -67.5532 450.21 -82 432.389 -82H283.957Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M319.755 208.777L290.778 314.592L396.593 343.569L425.569 237.754L319.755 208.777ZM314.717 153.868C297.529 149.162 279.78 159.28 275.073 176.468L235.869 319.629C231.162 336.817 241.28 354.567 258.469 359.273L401.63 398.477C418.818 403.184 436.567 393.066 441.274 375.878L480.478 232.717C485.185 215.528 475.067 197.779 457.879 193.072L314.717 153.868Z'
        fill='url(#paint0_linear_6_7)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M51.6285 221.317V331.028H161.339V221.317H51.6285ZM32.2678 169.689C14.4468 169.689 0 184.136 0 201.957V350.389C0 368.21 14.4468 382.656 32.2678 382.656H180.7C198.521 382.656 212.968 368.21 212.968 350.389V201.957C212.968 184.136 198.521 169.689 180.7 169.689H32.2678Z'
        fill='white'
      />
      <defs>
        <linearGradient
          id='paint0_linear_6_7'
          x1='352.5'
          y1='276'
          x2='330.049'
          y2='378.875'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#111111' stopOpacity='0.5' />
          <stop offset='1' stopColor='#00A8EF' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default BannerBackground;
