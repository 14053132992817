import { cn } from 'lib/utils';
import { Button } from 'components/ui/button';
import { useState } from 'react';
import { useAuth } from 'providers/AuthProvider';
import { Menu } from 'lucide-react';
import { NavBarOptionsByRole } from 'constants/roles';
import { useNavigate } from 'react-router-dom';
import { Paths } from 'constants/paths';

export function MainNav() {
  const { logOut, user } = useAuth();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav className='fixed z-50 w-full bg-white shadow-md'>
      <div className='mx-auto flex flex-wrap items-center justify-between p-4 sm:container'>
        <div className='flex items-center space-x-3 rtl:space-x-reverse'>
          <img
            src='/studia-logo.png'
            alt='Studia Logo'
            className='-ml-2 w-32 hover:cursor-pointer'
            onClick={() => navigate(Paths.Home)}
          />
        </div>
        <Button
          variant='outline'
          size='icon'
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className={'md:hidden'}
        >
          <Menu className='h-4 w-4' />
        </Button>
        <div
          className={cn(`w-full md:block md:w-auto ${!isMenuOpen && 'hidden'}`)}
          id='navbar-default'
        >
          <ul className='mt-4 flex flex-col rounded-lg border border-gray-100 bg-gray-50 p-4 font-medium dark:border-gray-700 dark:bg-gray-800 md:mt-0 md:flex-row md:items-baseline md:space-x-8 md:border-0 md:bg-white md:p-0 md:dark:bg-gray-900 rtl:space-x-reverse'>
            {NavBarOptionsByRole[user?.role].map(({ title, path }) => (
              <li key={path}>
                <a
                  href={path}
                  className='block rounded px-3 py-2 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:border-0 md:p-0 md:hover:bg-transparent md:hover:text-sky-500 md:dark:hover:bg-transparent md:dark:hover:text-blue-500'
                  aria-current='page'
                >
                  {title}
                </a>
              </li>
            ))}
            <Button onClick={logOut} className={'mt-4 md:mt-0'}>
              Sign Out
            </Button>
          </ul>
        </div>
      </div>
    </nav>
  );
}
