import Menu from 'components/common/Menu';
import BannerBackground from 'assets/BannerBackground';
import { useMediaQuery } from 'react-responsive';
import { useAuth } from 'providers/AuthProvider';
import StudentStats from 'components/StudentStats';
import { TypographyH2 } from 'components/ui/typography/typographyH2';

const StudentDashboard = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { user } = useAuth();

  return (
    <div className='px-4 py-6 sm:container space-y-8'>
      <div
        className={`bg-sky-500 text-white rounded-2xl shadow-xl flex justify-between items-center h-60 ${isMobile && 'bg-banner-texture bg-cover'}`}
      >
        <div className='p-10 max-w-xl space-y-2'>
          <p className='text-[32px] md:text-[40px] leading-[3rem] font-semibold'>
            Welcome {user.name}!
          </p>
          <p className='text-lg md:text-2xl font-light'>
            Start practicing and improve your English skills
          </p>
        </div>
        {!isMobile && <BannerBackground className='rounded-r-2xl h-60' />}
      </div>
      <div className='px-4 sm:px-6 space-y-4'>
        <TypographyH2>My Statistics</TypographyH2>
        <StudentStats studentId={user.id} />
      </div>
      <Menu />
    </div>
  );
};

export default StudentDashboard;
