import { Button } from 'components/ui/button';
import { ChevronLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const BackSection = ({ onClick }) => {
  const navigate = useNavigate();

  return (
    <section>
      <Button
        variant='link'
        className='flex gap-1 p-0 text-sky-500'
        onClick={() => (onClick ? onClick() : navigate(-1))}
      >
        <ChevronLeft size={16} />
        Back
      </Button>
    </section>
  );
};

export default BackSection;
