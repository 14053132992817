import { useQuery } from '@tanstack/react-query';
import {
  getClassroomCustomExercises,
  getCustomType,
  getCustomTypeExercisesByClassroom,
} from 'api/customExercises';

export const useGetClassroomCustomExercises = ({ classroomId, ...options }) => {
  const queryKey = ['classroom-custom-exercises', classroomId];

  return useQuery({
    queryKey,
    queryFn: () => getClassroomCustomExercises(classroomId),
    ...options,
  });
};

export const useGetCustomTypeExercisesByClassroom = ({
  customTypeId,
  classroomId,
  ...options
}) => {
  const queryKey = ['custom-type-exercises', customTypeId, classroomId];

  return useQuery({
    queryKey,
    queryFn: () =>
      getCustomTypeExercisesByClassroom({ customTypeId, classroomId }),
    ...options,
  });
};

export const useGetCustomType = (customTypeId, options) => {
  const queryKey = ['custom-type', customTypeId];

  return useQuery({
    queryKey,
    queryFn: () => getCustomType(customTypeId),
    ...options,
  });
};
