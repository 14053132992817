import ExerciseWrapper from 'components/exercises/ExerciseWrapper';
import ReadingExercise from 'components/exercises/ReadingExercise';
import { ExerciseType } from 'constants/exercises';

const ReadingExercisePage = () => (
  <ExerciseWrapper
    exercise={ReadingExercise}
    exerciseType={ExerciseType.Reading}
  />
);

export default ReadingExercisePage;
