import { ExerciseType } from 'constants/exercises';
import apiClient from './apiClient';

export const getUnattemptedExercise = async exerciseType => {
  const token = localStorage.getItem('token');

  const response = await apiClient.get(`exercises/${exerciseType}/exercise`, {
    headers: {
      Authorization: token,
    },
  });

  return response.data;
};

export const getExercise = async (exerciseId, exerciseType) => {
  const token = localStorage.getItem('token');

  const response = await apiClient.get(
    `exercises/${exerciseType}/${exerciseId}/`,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

export const getExercisesByType = async (
  exerciseType,
  { page = 1, size = 10, title = '' },
) => {
  const token = localStorage.getItem('token');

  const pageParam = encodeURIComponent(page);
  const sizeParam = encodeURIComponent(size);

  if (!title) {
    const response = await apiClient.get(
      `exercises/${exerciseType}/classrooms?page=${pageParam}&size=${sizeParam}`,
      {
        headers: {
          Authorization: token,
        },
      },
    );

    const data = await response.data;

    return {
      data: data.exercises,
      pageCount: data.total_pages,
    };
  }

  const titleParam = encodeURIComponent(title);

  const response = await apiClient.get(
    `exercises/${exerciseType}/classrooms?page=${pageParam}&size=${sizeParam}&title=${titleParam}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  const data = await response.data;

  return {
    data: data.exercises,
    pageCount: data.total_pages,
  };
};

const convertExercise = exercise => {
  const classrooms = [];

  exercise.active_classrooms.forEach(classroom => {
    classrooms.push({
      name: classroom.name,
      id: classroom.id,
      active: true,
    });
  });

  exercise.inactive_classrooms.forEach(classroom => {
    classrooms.push({
      name: classroom.name,
      id: classroom.id,
      active: false,
    });
  });

  classrooms.sort((a, b) => a.name.localeCompare(b.name));

  return {
    id: exercise.id,
    classrooms: classrooms,
    activeClassrooms: exercise.active_classrooms.map(c => c.id),
    inactiveClassrooms: exercise.inactive_classrooms.map(c => c.id),
  };
};

export const getExerciseClassrooms = async (exerciseId, exerciseType) => {
  const token = localStorage.getItem('token');

  const response = await apiClient.get(
    `exercises/${exerciseType}/${exerciseId}/classrooms`,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  const exercise = await response.data;

  return convertExercise(exercise);
};

export const updateExerciseClassrooms = async ({
  exerciseType,
  exerciseId,
  data,
}) => {
  const token = localStorage.getItem('token');

  const response = await apiClient.put(
    `exercises/${exerciseType}/${exerciseId}/classrooms`,
    data,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

export const getExerciseExplanation = async ({
  exerciseId,
  exerciseType,
  answers,
}) => {
  const token = localStorage.getItem('token');

  const response = await apiClient.post(
    `exercises/${exerciseType}/${exerciseId}/correction`,
    answers,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

export const getHint = async (exerciseId, questionNumber) => {
  const token = localStorage.getItem('token');

  const response = await apiClient.get(
    `/exercises/${ExerciseType.Reading}/${exerciseId}/hints?question_number=${questionNumber}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

export const getUncompletedExercises = async userId => {
  const token = localStorage.getItem('token');

  const response = await apiClient.get(
    `/chat/unattempted_exercises?user_id=${userId}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  const data = await response.data;

  return data.chats_by_exercise;
};
