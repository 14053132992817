const { MainNav } = require('components/ui/main-nav');
const { useAuth } = require('providers/AuthProvider');

const Layout = ({ children }) => {
  const { token } = useAuth();

  if (!token) return children;

  return (
    <>
      <MainNav />
      <main className='pt-[4.5rem]'>{children}</main>
    </>
  );
};

export default Layout;
