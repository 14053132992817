import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'components/ui/dialog';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { useForm } from 'react-hook-form';
import validator from 'lib/validator';
import { useEffect, useState } from 'react';
import { Input } from 'components/ui/input';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Space, notification } from 'antd';
import { createCustomExerciseAttempt } from 'api/customExercises';
import SelectInput from 'components/ui/select-input';
import { useGetClassroom } from 'hooks/useClassrooms';
import { useNavigate } from 'react-router-dom';

const schema = validator
  .object({
    studentId: validator.string().required('Student is required'),
    score: validator.number().required('Score is required').min(0).max(100),
    notes: validator.string().nullable(),
  })
  .required();

const CreateCustomExerciseAttemptDialog = ({ exerciseId, classroomId }) => {
  const [open, setOpen] = useState(false);
  const [notificationApi, contextHolder] = notification.useNotification();
  const queryClient = useQueryClient();
  const { data: classroom, isSuccess } = useGetClassroom(classroomId);
  const navigate = useNavigate();

  const openNotification = studentId => {
    const btn = (
      <Space>
        <Button
          variant='link'
          onClick={() => navigate(`/students/${studentId}`)}
        >
          View student
        </Button>
      </Space>
    );

    notificationApi['success']({
      message: `Attempt registered`,
      btn,
      placement: 'bottomRight',
    });
  };

  const { mutateAsync, error, isError, reset } = useMutation({
    mutationFn: createCustomExerciseAttempt,
    onSuccess: data => {
      setOpen(false);

      queryClient.refetchQueries(['attempts', data.user_id]);
      openNotification(data.user_id);
    },
  });

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      studentId: '',
      score: '',
      notes: '',
    },
  });

  const onSubmit = async data => {
    try {
      const scoreConverted = parseInt(data.score) / 100;

      await mutateAsync({
        exerciseId,
        data: { ...data, score: scoreConverted },
      });
    } catch (e) {
      console.log('error', e);
    }
  };

  useEffect(() => {
    form.reset();
  }, [open, form]);

  return (
    <>
      {contextHolder}
      <Dialog
        open={open}
        onOpenChange={() => {
          setOpen(prev => !prev);
          reset();
        }}
      >
        <DialogTrigger>
          <Button variant='outline'>Register attempt</Button>
        </DialogTrigger>
        <DialogContent className='flex max-h-[41rem] flex-col'>
          <DialogHeader>
            <DialogTitle>Register attempt</DialogTitle>
            <DialogDescription>
              Register a new attempt for this exercise
            </DialogDescription>
          </DialogHeader>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-6'>
              {isSuccess && (
                <SelectInput
                  control={form.control}
                  name='studentId'
                  label='Student'
                  items={classroom.students}
                  valueAccessor={student => student.id}
                  labelAccessor={student => student.name}
                  placeholder={'Select a student'}
                />
              )}
              <FormField
                control={form.control}
                name='score'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Score</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        placeholder='80'
                        type='number'
                        max={100}
                        min={0}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='notes'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Notes</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        placeholder='Really good pronunciation'
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div
                className={`flex w-full ${isError ? 'justify-between' : 'justify-end'}`}
              >
                {isError && <p className='text-red-500'>{error.message}</p>}
                <Button type='submit'>Save</Button>
              </div>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreateCustomExerciseAttemptDialog;
