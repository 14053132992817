import { Divider } from 'antd';
import Loader from 'components/common/Loader';
import ScoreBadge from 'components/common/ScoreBadge';
import { Card, CardContent, CardHeader } from 'components/ui/card';
import { TypographyH3 } from 'components/ui/typography/typographyH3';
import { TypographyH4 } from 'components/ui/typography/typographyH4';
import { useGetExercise } from 'hooks/useExercises';

const WritingAttempt = ({ attempt }) => {
  const {
    data: exercise,
    isLoading,
    isSuccess,
  } = useGetExercise({
    exerciseId: attempt?.exercise_id,
    type: attempt?.exercise_type,
    enabled: !!attempt,
  });

  if (isLoading) return <Loader />;

  if (!isSuccess) return <p>Error getting the exercise</p>;

  return (
    <div className='grid gap-5 md:grid-cols-2'>
      <Card>
        <CardHeader>
          <div className='flex items-center justify-between'>
            <TypographyH3 className='mb-0'>Student answer</TypographyH3>
            <ScoreBadge score={attempt.score * 100} />
          </div>
        </CardHeader>
        <CardContent>
          {attempt.user_answers[0]}
          {attempt.notes && (
            <>
              <Divider />
              <TypographyH4>Notes</TypographyH4>
              {attempt.notes}
            </>
          )}
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <TypographyH3>{exercise.title}</TypographyH3>
        </CardHeader>
        <CardContent className='text-justify leading-7'>
          {exercise.text.map((text, index) => (
            <p key={index}>{text}</p>
          ))}
        </CardContent>
      </Card>
    </div>
  );
};

export default WritingAttempt;
