import { useQuery } from '@tanstack/react-query';
import { getStudent, getStudentStats } from 'api/users';

export const useGetStudent = userId => {
  return useQuery({
    queryKey: ['student', userId],
    queryFn: () => getStudent(userId),
  });
};

export const useGetStudentStats = userId => {
  return useQuery({
    queryKey: ['student-stats', userId],
    queryFn: () => getStudentStats(userId),
    cacheTime: 0,
    staleTime: 0,
  });
};
