import AdminDashboard from 'components/dashboards/AdminDashboard';
import StudentDashboard from 'components/dashboards/StudentDashboard';
import TeacherDashboard from 'components/dashboards/TeacherDashboard';
import { Paths } from 'constants/paths';

export const Roles = {
  Admin: 'admin',
  Student: 'student',
  Teacher: 'teacher',
};

export const DashboardByRole = {
  [Roles.Teacher]: <TeacherDashboard />,
  [Roles.Admin]: <AdminDashboard />,
  [Roles.Student]: <StudentDashboard />,
};

export const NavBarOptionsByRole = {
  [Roles.Admin]: [
    {
      title: 'Classrooms',
      path: Paths.Home,
    },
    {
      title: 'Exercises',
      path: Paths.Exercises,
    },
  ],
  [Roles.Teacher]: [
    {
      title: 'Classrooms',
      path: Paths.Home,
    },
    {
      title: 'Exercises',
      path: Paths.Exercises,
    },
  ],
  [Roles.Student]: [
    {
      title: 'Home',
      path: Paths.Home,
    },
    {
      title: 'My Attempts',
      path: Paths.Attempts,
    },
  ],
};
