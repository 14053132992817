import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import validator from 'lib/validator';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { Input } from 'components/ui/input';
import { Button } from 'components/ui/button';
import { Card, CardHeader } from 'components/ui/card';
import { useNavigate } from 'react-router-dom';
import { useCreateClassroom } from 'hooks/useClassrooms';
import { useQueryClient } from '@tanstack/react-query';

const schema = validator.object({
  name: validator.string().required('Classroom name is required'),
});

const NewClassroomForm = () => {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutateAsync: createClassroom } = useCreateClassroom({
    onSuccess: classroom => {
      queryClient.refetchQueries(['classrooms']);
      navigate(`/classrooms/${classroom.id}`);
    },
    onError: error => {
      setError(error.message);
    },
  });

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
    },
  });

  return (
    <>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(createClassroom)}
          className='flex flex-col space-y-6'
        >
          <Card>
            <CardHeader>
              <FormField
                control={form.control}
                name='name'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Name</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </CardHeader>
          </Card>
          <div className='flex w-full items-center'>
            {error && <p className='text-red-500'>{error}</p>}
            <Button type='submit' className='ml-auto'>
              Create
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
};

export default NewClassroomForm;
