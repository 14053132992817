import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const usePaginate = ({ initialPage = 1, ...options }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(() => {
    return parseInt(searchParams.get('page')) || initialPage;
  });

  useEffect(() => {
    const currentParams = Object.fromEntries([...searchParams]);
    setSearchParams({ ...currentParams, page });
  }, [page, setSearchParams]);

  const { data, ...query } = useQuery({
    ...options,
    queryKey: [...options.queryKey, page],
    queryFn: () => options.queryFn({ page }),
    keepPreviousData: true,
  });

  return {
    ...query,
    data: data?.data,
    pageCount: data?.pageCount,
    page,
    setPage,
  };
};
