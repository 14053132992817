import { DataTableWithPagination } from 'components/ui/data-table-with-pagination';
import { useNavigate } from 'react-router-dom';

const columns = [
  {
    header: 'Student',
    accessorKey: 'name',
  },
  {
    header: 'Email',
    accessorKey: 'email',
  },
];

const StudentTable = ({ students }) => {
  const navigate = useNavigate();

  const onClickRow = row => {
    navigate(`/students/${row.original.id}`);
  };

  return (
    <DataTableWithPagination
      columns={columns}
      data={students}
      onClickRow={onClickRow}
    />
  );
};

export default StudentTable;
