import { getExercise } from 'api/exercises';
import BackSection from 'components/common/BackSection';
import Loader from 'components/common/Loader';
import ActiveExerciseDialog from 'components/dialogs/ActiveExerciseDialog';
import ReadOnlyReading from 'components/exercises/ReadOnly/ReadOnlyReading';
import ReadOnlyWriting from 'components/exercises/ReadOnly/ReadOnlyWriting';
import ReadOnlyMultipleChoice from 'components/exercises/ReadOnly/ReadyOnlyMultipleChoice';
import { TypographyH2 } from 'components/ui/typography/typographyH2';
import { ExerciseType, ExerciseTypeMap } from 'constants/exercises';
import { Roles } from 'constants/roles';
import { useGetExercise } from 'hooks/useExercises';
import { useAuth } from 'providers/AuthProvider';
import { useParams } from 'react-router-dom';

const getExerciseComponentByExerciseType = (exerciseType, exercise) => {
  const attemptByExerciseType = {
    [ExerciseType.Reading]: <ReadOnlyReading exercise={exercise} />,
    [ExerciseType.MultipleChoiceCloze]: (
      <ReadOnlyMultipleChoice exercise={exercise} />
    ),
    [ExerciseType.Writing]: <ReadOnlyWriting exercise={exercise} />,
  };

  return attemptByExerciseType[exerciseType];
};

const Exercise = ({ type }) => {
  const { exerciseId } = useParams();
  const { user } = useAuth();
  const {
    data: exercise,
    isLoading,
    isSuccess,
  } = useGetExercise({
    exerciseId,
    type,
  });

  if (isLoading) {
    return <Loader />;
  }

  if (!isSuccess) {
    return <p>Error getting exercise </p>;
  }

  return (
    <div className='flex flex-col gap-3 px-4 py-6 sm:container'>
      <BackSection />
      <section className='flex flex-col justify-between gap-4 sm:flex-row sm:items-start'>
        <div className='flex items-center gap-2'>
          <TypographyH2 className='pb-0'>
            {ExerciseTypeMap[type]}: {exercise.title}
          </TypographyH2>
        </div>
        {user.role === Roles.Teacher && (
          <ActiveExerciseDialog exerciseId={exercise.id} type={type} />
        )}
      </section>
      <section className='mt-2'>
        {getExerciseComponentByExerciseType(type, exercise)}
      </section>
    </div>
  );
};

export default Exercise;
