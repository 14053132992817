import { useAuth } from 'providers/AuthProvider';
import { DashboardByRole } from 'constants/roles';

const Home = () => {
  const { user } = useAuth();

  return DashboardByRole[user?.role];
};

export default Home;
