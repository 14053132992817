import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      return Promise.reject(
        new Error(error.response.data.message || 'An error occurred'),
      );
    }

    if (error.request) {
      return Promise.reject(new Error('No response received from server'));
    }

    return Promise.reject(
      new Error(error.message || 'An error occurred while making the request'),
    );
  },
);

export default apiClient;
