import Loader from 'components/common/Loader';
import ClassroomsTable from 'components/tables/ClassroomsTable/ClassroomsTable';
import { TypographyH2 } from 'components/ui/typography/typographyH2';
import JoinClassroomDialog from 'components/dialogs/JoinClassroomDialog';
import { useGetClassroomsByTeacher } from 'hooks/useClassrooms';

const TeacherDashboard = () => {
  const {
    data: classrooms,
    isLoading,
    isSuccess,
  } = useGetClassroomsByTeacher();

  if (isLoading) {
    return <Loader />;
  }

  if (!isSuccess) {
    return <div>There was an error fetching your classes</div>;
  }

  return (
    <div className='px-4 pt-6 sm:container'>
      <div className='mb-3 flex items-center justify-between'>
        <TypographyH2 className='pb-0'>My Classrooms</TypographyH2>
        <JoinClassroomDialog />
      </div>
      <ClassroomsTable classrooms={classrooms} />
    </div>
  );
};

export default TeacherDashboard;
