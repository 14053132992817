import { useMutation, useQuery } from '@tanstack/react-query';
import {
  createClassroom,
  getClassroom,
  getClassroomStats,
  getClassrooms,
  getClassroomsByTeacher,
} from 'api/classrooms';
import { useAuth } from 'providers/AuthProvider';

export const useGetAllClassrooms = () => {
  return useQuery({
    queryKey: ['classrooms'],
    queryFn: getClassrooms,
  });
};

export const useGetClassroomsByTeacher = () => {
  const { user } = useAuth();

  return useQuery({
    queryKey: ['classrooms', user.id],
    queryFn: () => getClassroomsByTeacher(user.id),
  });
};

export const useGetClassroom = (classroomId, options) => {
  return useQuery({
    queryKey: ['classroom', classroomId],
    queryFn: () => getClassroom(classroomId),
    ...options,
  });
};

export const useCreateClassroom = props => {
  return useMutation({
    mutationFn: createClassroom,
    ...props,
  });
};

export const useClassroomStats = classroomId => {
  return useQuery({
    queryKey: ['classroom-stats', classroomId],
    queryFn: () => getClassroomStats(classroomId),
    cacheTime: 0,
    staleTime: 0,
  });
};
