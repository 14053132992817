import BannerBackground from 'assets/BannerBackground';
import { useMediaQuery } from 'react-responsive';

const Banner = ({ title, description, height = '' }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <div
      className={`bg-sky-500 text-white rounded-2xl shadow-xl flex justify-between items-center ${height ? height : 'min-h-60'} ${isMobile && 'bg-banner-texture bg-cover'}`}
    >
      <div className='p-10 space-y-2'>
        <p className='text-[32px] md:text-[40px] leading-[3rem] font-semibold'>
          {title}
        </p>
        <p className='text-md md:text-xl font-light md:max-w-[45rem]'>
          {description}
        </p>
      </div>
      {!isMobile && (
        <BannerBackground className={`rounded-r-2xl opacity-25 ${height}`} />
      )}
    </div>
  );
};

export default Banner;
