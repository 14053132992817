import { useNavigate, useParams } from 'react-router-dom';
import Loader, { SectionLoader } from 'components/common/Loader';
import StudentTable from 'components/tables/StudentTable';
import BackSection from 'components/common/BackSection';
import { TypographyH3 } from 'components/ui/typography/typographyH3';
import TeachersTable from 'components/tables/TeachersTable';
import { useClassroomStats, useGetClassroom } from 'hooks/useClassrooms';
import { Card } from 'antd';
import Banner from 'components/common/Banner';
import { useGetClassroomCustomExercises } from 'hooks/useCustomExercise';
import CreateCustomExerciseTypeDialog from 'components/dialogs/CreateCustomExerciseTypeDialog';
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs';
import { useState } from 'react';
import EmptyState from 'components/ui/empty-state';
import { toTitleCase } from 'lib/utils';
import { ExerciseTypeMap } from 'constants/exercises';
import { useAuth } from 'providers/AuthProvider';
import { Roles } from 'constants/roles';

const TabOptions = {
  CustomExercises: 'Custom Exercises',
  Teachers: 'Teachers',
  Students: 'Students',
  Statistics: 'Statistics',
};

const Classroom = () => {
  const navigate = useNavigate();
  const { classroomId } = useParams();
  const { user } = useAuth();

  const {
    data: classroom,
    isLoading,
    isSuccess,
  } = useGetClassroom(classroomId);
  const [tab, setTab] = useState(TabOptions.Students);

  const {
    data: customExercises,
    isLoading: customExercisesIsLoading,
    isSuccess: customExercisesIsSuccess,
  } = useGetClassroomCustomExercises({ classroomId });

  const {
    data: stats,
    isLoading: statsIsLoading,
    isSuccess: statsIsSuccess,
  } = useClassroomStats(classroomId);

  const onExerciseClick = exerciseId => {
    navigate(`/classrooms/${classroomId}/custom-exercises/${exerciseId}`);
  };

  if (isLoading || customExercisesIsLoading) {
    return <Loader />;
  }

  if (!isSuccess) {
    return (
      <div className='px-4 pt-6 sm:container'>
        <BackSection />
        <EmptyState message='Classroom not found' />
      </div>
    );
  }

  return (
    <div className='px-4 py-6 sm:container'>
      <BackSection />
      <Banner title={`Classroom ${classroom.name}`} height='h-40' />
      <div className='space-y-3 mt-5'>
        <Tabs
          defaultValue={TabOptions.Students}
          className='w-full'
          value={tab}
          onValueChange={newTab => setTab(newTab)}
        >
          <TabsList className='w-full'>
            <TabsTrigger value={TabOptions.Students} className='w-full'>
              {TabOptions.Students}
            </TabsTrigger>
            <TabsTrigger value={TabOptions.Teachers} className='w-full'>
              {TabOptions.Teachers}
            </TabsTrigger>
            <TabsTrigger value={TabOptions.CustomExercises} className='w-full'>
              {TabOptions.CustomExercises}
            </TabsTrigger>
            <TabsTrigger value={TabOptions.Statistics} className='w-full'>
              {TabOptions.Statistics}
            </TabsTrigger>
          </TabsList>
          <TabsContent value={TabOptions.Students}>
            <TypographyH3 className='my-3'>Students</TypographyH3>
            <StudentTable students={classroom.students} />
          </TabsContent>
          <TabsContent value={TabOptions.Teachers}>
            <TypographyH3 className='my-3'>Teachers</TypographyH3>
            <TeachersTable teachers={classroom.teachers} />
          </TabsContent>
          <TabsContent value={TabOptions.CustomExercises}>
            {customExercisesIsLoading ? (
              <SectionLoader />
            ) : customExercisesIsSuccess ? (
              <div className='space-y-4 mt-3 h-full'>
                <div className='flex w-full justify-between items-center'>
                  <TypographyH3>Classroom's custom exercise types</TypographyH3>
                  {user.role === Roles.Teacher && (
                    <CreateCustomExerciseTypeDialog classroomId={classroomId} />
                  )}
                </div>
                {customExercises.length > 0 ? (
                  <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3	lg:grid-cols-4 gap-2'>
                    {customExercises.map(exercise => (
                      <Card
                        key={exercise.id}
                        className='mb-3 shadow-sm hover:shadow-md hover:cursor-pointer'
                        onClick={() => onExerciseClick(exercise.id)}
                      >
                        <p className='font-bold'>{exercise.name}</p>
                      </Card>
                    ))}
                  </div>
                ) : (
                  <EmptyState message='No custom exercises yet' />
                )}
              </div>
            ) : (
              <EmptyState message='Custom exercises not found' />
            )}
          </TabsContent>
          <TabsContent value={TabOptions.Statistics}>
            <TypographyH3 className='my-3'>Statistics</TypographyH3>
            {statsIsLoading ? (
              <SectionLoader />
            ) : statsIsSuccess ? (
              <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3	lg:grid-cols-4 gap-2'>
                <Card className='min-w-32 bg-[#0ea5e999]'>
                  <p className='text-sm text-white font-semibold'>
                    Average score
                  </p>
                  <TypographyH3 className='text-white mb-0'>
                    {Math.round(stats.average_score * 100)}%
                  </TypographyH3>
                </Card>
                {stats.breakdown.map(breakdown => (
                  <Card className='min-w-32' key={breakdown.exercise_type}>
                    <p className='text-sm text-gray-500'>
                      {toTitleCase(
                        ExerciseTypeMap[breakdown.exercise_type] ||
                          breakdown.exercise_type,
                      )}
                    </p>
                    <TypographyH3 className='text-sky-600'>
                      {Math.round(breakdown.average_score * 100)}%
                    </TypographyH3>
                  </Card>
                ))}
              </div>
            ) : (
              <EmptyState message='No statistics yet' />
            )}
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
};

export default Classroom;
