import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Button } from 'components/ui/button';
import EmptyState from 'components/ui/empty-state';
import { Input } from 'components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/ui/table';
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';
import { useMemo, useState } from 'react';

export const FilterType = {
  TEXT: 'text',
  SELECT: 'select',
};

export function TextFilter({ table, columnId, placeholder }) {
  return (
    <Input
      placeholder={placeholder}
      value={table.getColumn(columnId)?.getFilterValue() ?? ''}
      onChange={event =>
        table.getColumn(columnId)?.setFilterValue(event.target.value)
      }
    />
  );
}

export const ALL_OPTION = 'all';

export function SelectFilter({ table, columnId, placeholder, options }) {
  return (
    <Select
      onValueChange={value => {
        const column = table.getColumn(columnId);

        if (value === ALL_OPTION) return column?.setFilterValue(undefined);
        return column?.setFilterValue(value);
      }}
    >
      <SelectTrigger>
        <SelectValue placeholder={placeholder} />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value={ALL_OPTION}>All</SelectItem>
        {options?.map(option => (
          <SelectItem key={option.value} value={option.value}>
            {option.label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}

export function DataTable({
  columns,
  data,
  onClickRow,
  filters,
  page,
  pageSize = 10,
  pageCount,
  setPage = () => {},
}) {
  const [columnFilters, setColumnFilters] = useState([]);

  const pagination = useMemo(
    () => ({
      pageIndex: page - 1,
      pageSize,
    }),
    [page, pageSize],
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    pageCount: pageCount ?? -1,
    state: {
      columnFilters,
      pagination,
    },
    manualPagination: true,
  });

  const handlePreviousPage = () => {
    if (table.getCanPreviousPage()) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (table.getCanNextPage()) {
      setPage(page + 1);
    }
  };

  return (
    <>
      {filters && (
        <div className='mb-3 flex gap-2'>
          {filters.map(({ type = FilterType.TEXT, columnId, ...filter }) =>
            type === FilterType.TEXT ? (
              <TextFilter
                key={columnId}
                table={table}
                columnId={columnId}
                {...filter}
              />
            ) : (
              <SelectFilter
                key={columnId}
                table={table}
                columnId={columnId}
                {...filter}
              />
            ),
          )}
        </div>
      )}
      <div className='rounded-md border'>
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map(row => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                  onClick={() => onClickRow?.(row)}
                  className={onClickRow ? 'cursor-pointer' : ''}
                >
                  {row.getVisibleCells().map(cell => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className='h-24 text-center'
                >
                  <EmptyState message='No data found' />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {pageCount > 0 && (
        <div className='mt-2 flex justify-end space-x-2'>
          <div className='flex items-center justify-center text-sm font-medium'>
            Page {table.getState().pagination.pageIndex + 1} of{' '}
            {table.getPageCount()}
          </div>
          <div className='flex items-center space-x-2'>
            <Button
              aria-label='Go to previous page'
              variant='outline'
              size='icon'
              className='size-8'
              onClick={handlePreviousPage}
              disabled={!table.getCanPreviousPage()}
            >
              <ChevronLeftIcon className='size-4' aria-hidden='true' />
            </Button>
            <Button
              aria-label='Go to next page'
              variant='outline'
              size='icon'
              className='size-8'
              onClick={handleNextPage}
              disabled={!table.getCanNextPage()}
            >
              <ChevronRightIcon className='size-4' aria-hidden='true' />
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
