import { Card } from 'antd';
import { EXERCISES, ExerciseType, ExerciseTypeMap } from 'constants/exercises';
import { TypographyH2 } from 'components/ui/typography/typographyH2';
import { useGetStudentStats } from 'hooks/useUsers';
import Loader, { SectionLoader } from 'components/common/Loader';
import EmptyState from 'components/ui/empty-state';
import { TypographyH3 } from 'components/ui/typography/typographyH3';
import { toTitleCase } from 'lib/utils';

const StudentStats = ({ studentId }) => {
  const { data: stats, isLoading, isSuccess } = useGetStudentStats(studentId);

  if (isLoading) {
    return <SectionLoader />;
  }

  if (!isSuccess) {
    return <EmptyState message='No stats for this student' />;
  }

  const colsSm = Math.min(2, stats.breakdown.length + 1);
  const colsMd = Math.min(3, stats.breakdown.length + 1);
  const colsLg = Math.min(5, stats.breakdown.length + 1);

  const gridColsClassSm = {
    1: 'sm:grid-cols-1',
    2: 'sm:grid-cols-2',
  }[colsSm];

  const gridColsClassMd = {
    1: 'md:grid-cols-1',
    2: 'md:grid-cols-2',
    3: 'md:grid-cols-3',
  }[colsMd];

  const gridColsClassLg = {
    1: 'lg:grid-cols-1',
    2: 'lg:grid-cols-2',
    3: 'lg:grid-cols-3',
    4: 'lg:grid-cols-4',
    5: 'lg:grid-cols-5',
  }[colsLg];

  return (
    <div
      className={`grid gap-2 ${gridColsClassMd} ${gridColsClassLg} ${gridColsClassSm} lg:gap-4`}
    >
      <Card className='min-w-32 bg-[#0ea5e999]'>
        <p className='text-sm text-white font-semibold'>Average score</p>
        <TypographyH3 className='text-white mb-0'>
          {Math.round(stats.average_score * 100)}%
        </TypographyH3>
      </Card>
      {stats.breakdown.map(breakdown => (
        <Card className='min-w-32' key={breakdown.exercise_type}>
          <p className='text-sm text-gray-500'>
            {toTitleCase(
              ExerciseTypeMap[breakdown.exercise_type] ||
                breakdown.exercise_type,
            )}
          </p>
          <TypographyH3 className='text-sky-600'>
            {Math.round(breakdown.average_score * 100)}%
          </TypographyH3>
        </Card>
      ))}
    </div>
  );
};

export default StudentStats;
