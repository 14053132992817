import Loader from 'components/common/Loader';
import ClassroomsTable from 'components/tables/ClassroomsTable/ClassroomsTable';
import { Button } from 'components/ui/button';
import { TypographyH2 } from 'components/ui/typography/typographyH2';
import { Paths } from 'constants/paths';
import { useGetAllClassrooms } from 'hooks/useClassrooms';
import { useNavigate } from 'react-router-dom';

const AdminDashboard = () => {
  const navigate = useNavigate();
  const { data: classrooms, isLoading, isSuccess } = useGetAllClassrooms();

  const onClick = () => navigate(Paths.NewClassroom);

  if (isLoading) {
    return <Loader />;
  }

  if (!isSuccess) {
    return <div>There was an error fetching the classrooms</div>;
  }

  return (
    <div className='px-4 pt-6 sm:container'>
      <div className='flex w-full justify-between'>
        <TypographyH2 className='mb-3'>All Classrooms</TypographyH2>
        <Button onClick={onClick}>Create new classroom</Button>
      </div>
      <ClassroomsTable classrooms={classrooms} />
    </div>
  );
};

export default AdminDashboard;
