import { useQuery } from '@tanstack/react-query';
import { getUserAttempts } from 'api/attempts';
import { getAttempt } from 'api/attempts';

export const useGetAttempt = attemptId => {
  return useQuery({
    queryKey: ['attempt', attemptId],
    queryFn: () => getAttempt(attemptId),
  });
};

export const useGetAllUserAttempts = userId => {
  return useQuery({
    queryKey: ['attempts', userId],
    queryFn: () => getUserAttempts(userId),
  });
};
