import { cn } from 'lib/utils';

export function TypographyH4({ children, className }) {
  return (
    <h4
      className={cn(
        'mb-2 scroll-m-20 text-xl font-semibold tracking-tight',
        className,
      )}
    >
      {children}
    </h4>
  );
}
