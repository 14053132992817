import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Button } from 'components/ui/button';
import { FilterType, SelectFilter, TextFilter } from 'components/ui/data-table';
import EmptyState from 'components/ui/empty-state';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/ui/table';
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

export function DataTableWithPagination({
  columns,
  data,
  onClickRow,
  filters,
  rowsPerPage = 10,
  card: Card,
  customCard = false,
}) {
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  const [columnFilters, setColumnFilters] = useState([]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    initialState: {
      pagination: {
        pageSize: rowsPerPage,
      },
    },
    state: {
      columnFilters,
    },
  });

  return (
    <>
      {filters && (
        <div className='mb-3 flex gap-2 flex-col md:flex-row'>
          {filters.map(({ type = FilterType.TEXT, columnId, ...filter }) =>
            type === FilterType.TEXT ? (
              <TextFilter
                key={columnId}
                table={table}
                columnId={columnId}
                {...filter}
              />
            ) : (
              <SelectFilter
                key={columnId}
                table={table}
                columnId={columnId}
                {...filter}
              />
            ),
          )}
        </div>
      )}
      <div className='rounded-md border'>
        {isMobile ? (
          table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map(row =>
              customCard ? (
                <Card row={row} onClickRow={onClickRow} />
              ) : (
                <div
                  key={row.id}
                  onClick={() => onClickRow?.(row)}
                  className={`${onClickRow && 'cursor-pointer'} flex flex-col p-4 border-b hover:bg-slate-100`}
                >
                  {row.getVisibleCells().map(cell => (
                    <div key={cell.id}>
                      {cell.column.id === columns[0].id ? (
                        <strong>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </strong>
                      ) : (
                        <div>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ),
            )
          ) : (
            <div className='h-24 text-center'>
              <EmptyState message='No data found' />
            </div>
          )
        ) : (
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map(headerGroup => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map(header => {
                    return (
                      <TableHead key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map(row => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && 'selected'}
                    onClick={() => onClickRow?.(row)}
                    className={onClickRow ? 'cursor-pointer' : ''}
                  >
                    {row.getVisibleCells().map(cell => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className='h-24 text-center'
                  >
                    <EmptyState message='No data found' />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </div>
      {table.getPageCount() > 1 && (
        <div className='flex items-center justify-end space-x-2 py-4'>
          <Button
            variant='outline'
            size='sm'
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Previous
          </Button>
          <Button
            variant='outline'
            size='sm'
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next
          </Button>
        </div>
      )}
    </>
  );
}
