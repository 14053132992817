import { useForm } from 'react-hook-form';
import { Input } from 'components/ui/input';
import { Button } from 'components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { useAuth } from 'providers/AuthProvider';
import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import validator from 'lib/validator';
import { PasswordInput } from 'components/ui/password-input';

const schema = validator
  .object({
    email: validator.string().email().required('Email is required'),
    password: validator
      .string()
      .min(8, 'Password must be at least 8 characters')
      .minLowercase(1, 'Password must contain at least 1 lowercase letter')
      .minUppercase(1, 'Password must contain at least 1 uppercase letter')
      .minNumbers(1, 'Password must contain at least 1 number')
      .required('Password is required'),
  })
  .required();

const SignInForm = () => {
  const { signInAction } = useAuth();
  const [error, setError] = useState(null); // [1

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = async data => {
    try {
      setError(null);

      await signInAction(data);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-6'>
          <FormField
            control={form.control}
            name='email'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='password'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Password</FormLabel>
                <FormControl>
                  <PasswordInput {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {error && <p className='mb-4 text-red-500'>{error}</p>}
          <Button type='submit' className='!mt-8 w-full'>
            Sign in
          </Button>
        </form>
      </Form>
    </>
  );
};

export default SignInForm;
