import { DataTable } from 'components/ui/data-table';
import { useNavigate } from 'react-router-dom';

const columns = [
  {
    header: 'Classroom',
    accessorKey: 'name',
  },
  {
    header: 'Student count',
    accessorKey: 'student_count',
  },
  {
    header: 'Teacher count',
    accessorKey: 'teacher_count',
  },
  {
    header: 'Teacher code',
    accessorKey: 'teacher_code',
  },
  {
    header: 'Student code',
    accessorKey: 'student_code',
  },
];

const ClassroomsTable = ({ classrooms }) => {
  const navigate = useNavigate();

  const onClickRow = row => {
    return navigate(`/classrooms/${row.original.id}`);
  };

  return (
    <DataTable columns={columns} data={classrooms} onClickRow={onClickRow} />
  );
};

export default ClassroomsTable;
