import MultipleChoiceClozeExercise from 'components/exercises/MultipleChoiceClozeExercise';
import ExerciseWrapper from 'components/exercises/ExerciseWrapper';
import { ExerciseType } from 'constants/exercises';

const MultipleChoiceClozeExercisePage = () => (
  <ExerciseWrapper
    exercise={MultipleChoiceClozeExercise}
    exerciseType={ExerciseType.MultipleChoiceCloze}
  />
);

export default MultipleChoiceClozeExercisePage;
